import { defineStore } from 'pinia'

import {
    attachNumbers,
    buyNumberWithAttach,
    detachNumbers,
    getAvailableNumbers,
    updateAttachedNumber,
} from '@/api/call-tracking/campaign/campaign-actions-routes'
import { updateNumbersStatus } from '@/api/number-routes'
import { getFilterOnPageInLocalStorageByWorkspaceId, setFilterOnPageInLocalStorageByWorkspaceId } from '@/helpers/save-sorting-local-storage-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useDetailCampaignTrackingNumberTableStore } from '@/store/call-tracking/campaigns/detailCampaignTrackingNumberTable'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_STATUS } from '@/constants/localFilters'
import { NUMBERABLE_TYPES } from '@/constants/numberableTypes'

export const useDetailCampaignTrackingNumberStore = defineStore('detailCampaignTrackingNumberStore', {
    state: () => ({
        loading: false,
        actionLoading: false,
        countersLoading: {},

        selectedNumberSlug: null,

        onPageAvailableNumbers: 25,

        trackingNumberFormData: {},

        availableNumbers: [],
        availableNumbersFilters: [
            FILTER_STATUS,
        ],

        selectedTrackingNumbers: [],
    }),

    getters: {
        selectedNumber(state) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            return state.selectedNumberSlug ? detailCampaignTrackingNumberTableStore.numbers.find((n) => n.slug === state.selectedNumberSlug) : null
        },

        attachedVendor(state) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            return (state.selectedNumberSlug && this.selectedNumber?.vendor_id)
                ? detailCampaignTrackingNumberTableStore.attachedVendors.find((v) => this.selectedNumber.vendor_id === v.pivot.vendor_id && v.pivot.numberable_id === this.selectedNumber.id && v.pivot.numberable_type === NUMBERABLE_TYPES.STATIC_NUMBER)
                : null
        },
    },

    actions: {
        changeOnPageAvailableNumbers(onPage) {
            this.onPageAvailableNumbers = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber)
        },

        getLocalStorageOnPageAvailableNumbers() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber)

            this.onPageAvailableNumbers = savedOnPage ? Number(savedOnPage) : 25
        },

        getQueryLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()
            const localFilterTableStore = useLocalFilterTableStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber, this.availableNumbersFilters)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.campaignAvailableTrackingNumber, detailCampaignStore.id)
            this.getLocalStorageOnPageAvailableNumbers()
        },

        setTrackingNumber(trackingNumber) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            this.selectedNumberSlug = trackingNumber.slug

            const {
                slug, name, traffic_source, slave_pool, conversion, vendor, cap_enabled, cap_on_id, global_cap, monthly_cap, daily_cap, hourly_cap, max_concurrency_enabled, max_concurrency, facebook_ads_event_id,
            } = trackingNumber

            this.trackingNumberFormData.number = slug
            this.trackingNumberFormData.name = name
            this.trackingNumberFormData.traffic_source = traffic_source ? traffic_source.slug : null
            this.trackingNumberFormData.vendor = vendor ? vendor.slug : null
            this.trackingNumberFormData.number_pool = slave_pool ? slave_pool.slug : null
            this.trackingNumberFormData.conversion = conversion ? conversion.slug : null

            this.trackingNumberFormData.cap_enabled = cap_enabled
            this.trackingNumberFormData.cap_on_id = cap_on_id
            this.trackingNumberFormData.global_cap = global_cap
            this.trackingNumberFormData.monthly_cap = monthly_cap
            this.trackingNumberFormData.daily_cap = daily_cap
            this.trackingNumberFormData.hourly_cap = hourly_cap

            this.trackingNumberFormData.max_concurrency_enabled = max_concurrency_enabled
            this.trackingNumberFormData.max_concurrency = max_concurrency

            this.trackingNumberFormData.facebook_ads_event_id = facebook_ads_event_id || undefined

            this.trackingNumberFormData.payout = null
            this.trackingNumberFormData.convert_on_id = null
            this.trackingNumberFormData.duplicate_id = null
            this.trackingNumberFormData.length = null
            this.trackingNumberFormData.time_limit = null

            const findAttachedVendor = vendor ? detailCampaignTrackingNumberTableStore.attachedVendors.find((v) => v.slug === vendor.slug) : null

            if (findAttachedVendor) {
                const settings = findAttachedVendor.pivot

                this.trackingNumberFormData.payout = settings.payout
                this.trackingNumberFormData.convert_on_id = settings.convert_on_id
                this.trackingNumberFormData.duplicate_id = settings.duplicate_id
                this.trackingNumberFormData.length = settings.length
                this.trackingNumberFormData.time_limit = settings.time_limit
            }
        },

        async goToBuyNumberWithAttach({ formData, slug }) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await buyNumberWithAttach({ formData, slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.setAttachedNumbersAndVendors(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success, payload }
        },

        async goToAttachNumbersToCampaign({ numbers = [], slug }) {
            const defaultStore = useDefaultStore()

            if (!numbers.length) {
                return
            }

            this.actionLoading = true

            const { success, payload, message } = await attachNumbers({
                formData: { number_slugs: numbers.map((n) => n.slug) },
                slug,
            })

            if (success) {
                this.setAttachedNumbersAndVendors(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToLoadAvailableNumbers(slug, withoutLoading = false) {
            const defaultStore = useDefaultStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getAvailableNumbers(slug)

            if (success) {
                this.availableNumbers = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToUpdateCounterTrackingNumber({ formData, counterName, campaignSlug }) {
            this.setTrackingNumber(formData)

            const counterLoadingKey = `${formData.slug}-${counterName}`

            this.changeCountersLoading({ counterLoadingKey })

            await this.goToUpdateTrackingNumber({
                max_concurrency: formData.max_concurrency,
            }, campaignSlug, counterLoadingKey)
        },

        changeCountersLoading({ counterLoadingKey, value = true }) {
            this.countersLoading[counterLoadingKey] = value
        },

        async goToUpdateTrackingNumber(formData, slug, counterLoadingKey = null) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formDataForSend = {
                ...this.trackingNumberFormData,
                ...formData,
            }

            const { success, payload, message } = await updateAttachedNumber({
                formData: formDataForSend,
                slug,
            })

            if (success) {
                this.trackingNumberFormData = formDataForSend

                defaultStore.setSuccessMessage({ message })

                this.setAttachedNumbersAndVendors(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            if (counterLoadingKey) {
                this.changeCountersLoading({ counterLoadingKey, value: false })
            }

            return { success }
        },

        setAttachedNumbersAndVendors({ numbers = [], vendors = [], phone_number_pools = [] }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()

            detailCampaignTrackingNumberTableStore.setVendors(vendors)

            const parseNumbers = detailCampaignTrackingNumberTableStore.parseNumbers({ numbers })
            const parseNumberPools = detailCampaignTrackingNumberTableStore.parseNumberPools({ phone_number_pools })

            detailCampaignTrackingNumberTableStore.setNumbers(parseNumbers)
            detailCampaignTrackingNumberTableStore.setNumberPools(parseNumberPools)
        },

        async goToDetachTrackingNumber({ trackingNumber = null, trackingNumbers = [], slug }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formData = {}

            if (trackingNumber) {
                formData.number_slugs = [trackingNumber.slug]
            } else {
                formData.number_slugs = trackingNumbers.map(({ slug }) => slug)
            }

            const { success, message } = await detachNumbers({ data: formData, slug })

            if (success) {
                detailCampaignTrackingNumberTableStore._removeTrackingNumbersAfterDelete(formData.number_slugs)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToUpdateTrackingNumberStatus({ slugs, status_id }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, payload, message } = await updateNumbersStatus({ slugs, status_id })

            if (success) {
                detailCampaignTrackingNumberTableStore.updateTrackingNumberStatus(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        changeSelectedTrackingNumbers(newSelectedItems) {
            this.selectedTrackingNumbers = newSelectedItems
        },

        clearSelectedTrackingNumbers() {
            this.selectedTrackingNumbers = []
        },
    },
})
