import { defineStore } from 'pinia'

import {
    getLeads, getLeadsFiltersAndSorting, deleteLeads, exportLeads,
} from '@/api/leads-routes'

import { getQuery, setQuery, createRangeForSend } from '@/helpers/filters-sorting-header-table-helper'
import { getFilterOnPageInLocalStorageByWorkspaceId, setFilterOnPageInLocalStorageByWorkspaceId } from '@/helpers/save-sorting-local-storage-helper'
import { downloadFile, removeFirstPlusForSearch } from '@/helpers/app-helper'
import { getCurrentTimezone, setTimezoneInLocalStorage } from '@/helpers/workspace-timezone-helper'
import { formatDateByTzOffset, getPresets } from '@/helpers/date-helper'
import { setRangeInLocalStorageByWorkspaceId, getRangeLocalStorageByWorkspaceId } from '@/helpers/save-range-local-storage-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { useAppStore } from '@/store/appStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'

export const useLeadsStore = defineStore('leadsStore', {
    state: () => ({
        loading: false,
        firstLoading: true,
        actionLoading: false,
        loadingCSV: false,

        items: [],
        selected: [],

        page: 1,
        total: 1,
        onPage: 25,

        search: null,

        currentTimezone: getCurrentTimezone(SETTINGS_HEADER_TABLE.leads) || useAuthorizationStore().getWorkspaceTimezoneId,
        range: [],
        rangeForSend: {},
        currentPreset: {},
    }),

    getters: {
        timezoneOffset({ currentTimezone }) {
            const appStore = useAppStore()
            return appStore.getTimezoneOffsetById(currentTimezone)
        },

        presets() {
            return getPresets(this.timezoneOffset)
        },

        bodyDateForSend({ currentTimezone, range = [] }) {
            return {
                date: range[0],
                dateTo: range[1],
                timezone: currentTimezone,
            }
        },
    },

    actions: {
        setCurrentTimezone(id) {
            this.currentTimezone = id
            setTimezoneInLocalStorage(id, SETTINGS_HEADER_TABLE.leads)
        },

        setRange(newRange) {
            this.range = newRange

            this.checkCurrentPreset(newRange)
            this.sendFiltersSorting()
        },

        sendFiltersSorting() {
            this.preparationRangeForSend()
            setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.leads)
            this.saveQuery()
            this.changePage(1)
        },

        setDefaultRange() {
            this.range = [formatDateByTzOffset(this.timezoneOffset), formatDateByTzOffset(this.timezoneOffset)]
        },

        checkCurrentPreset(range) {
            this.currentPreset = this.presets.find((preset) => {
                if (!preset.hasOwnProperty('range')) {
                    return true
                }

                return preset.range[0] === range[0] && preset.range[1] === range[1]
            })
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        clearSelected() {
            this.selected = []
        },

        changeOnPage(onPage) {
            this.onPage = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, SETTINGS_HEADER_TABLE.leads)

            this.goToLoadLeads()
        },

        getLocalStorageOnPage() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.leads)

            this.onPage = savedOnPage ? Number(savedOnPage) : 25
        },

        checkValidPage() {
            if (this.page > this.total) {
                this.changePage(this.total)
            } else {
                this.loading = false
            }
        },

        changePage(newPage) {
            this.page = newPage

            this.goToLoadLeads()
        },

        goToAfterGlobalSearch(searchValue) {
            setTimeout(() => {
                this.getQueryRange()
                this.getLocalStorageRange()
                this.goToSetRange()
                this.onSearch(searchValue)
            }, 0)
        },

        onSearch(searchValue) {
            this.search = searchValue

            if (!this.firstLoading) {
                this.changePage(1)
            }
        },

        async goToLoadLeadsFiltersAndSorting() {
            const { success, payload, message } = await getLeadsFiltersAndSorting()

            if (success) {
                const sortFilterTableStore = useSortFilterTableStore()
                sortFilterTableStore.setFilters(SETTINGS_HEADER_TABLE.leads, payload.filters)
                sortFilterTableStore.setSorting(SETTINGS_HEADER_TABLE.leads, payload.sorting)
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToDeleteLead(slugs) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await deleteLeads({ slugs })

            if (success) {
                this.goToLoadLeads()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToLoadLeads() {
            const sortFilterTableStore = useSortFilterTableStore()
            const { filtersForSend, sortingForSend } = sortFilterTableStore.getTable(SETTINGS_HEADER_TABLE.leads)

            this.clearSelected()

            this.loading = true

            const { success, payload, message } = await getLeads({
                page: this.page,
                search: removeFirstPlusForSearch(this.search),
                onPage: this.onPage,
                ...filtersForSend,
                ...sortingForSend,
                ...this.bodyDateForSend,
            })

            if (success) {
                const { data = [], current_page, last_page } = payload

                this.items = data
                this.page = current_page
                this.total = last_page

                this.checkValidPage()
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToExportLeads(type) {
            const defaultStore = useDefaultStore()

            this.loadingCSV = true

            const res = await exportLeads({
                type,
                ...this.bodyDateForSend,
            })

            if (res?.data && res.data.success === false) {
                !!res.data.message && defaultStore.setErrorMessage({ message: res.data.message })
            } else if (res?.success === false) {
                !!res.message && defaultStore.setErrorMessage({ message: res.message })
            } else {
                downloadFile(res, type)
            }

            this.loadingCSV = false
        },

        async getQueryLocalStorage() {
            this.getLocalStorageOnPage()

            this.setCurrentTimezone(getCurrentTimezone(SETTINGS_HEADER_TABLE.leads) || useAuthorizationStore().getWorkspaceTimezoneId)

            this.getQueryRange()
            this.getLocalStorageRange()

            this.goToSetRange()
            await this.goToLoadLeads()

            this.firstLoading = false
        },

        preparationRangeForSend() {
            this.rangeForSend = createRangeForSend(this.range, this.currentPreset)
        },

        saveQuery() {
            const sortFilterTableStore = useSortFilterTableStore()
            const { filtersForSend, sortingForSend } = sortFilterTableStore.getTable(SETTINGS_HEADER_TABLE.leads)

            const query = {
                ...setQuery(sortingForSend, filtersForSend),
                ...this.rangeForSend,
            }
            this.router.replace({ query }).catch(() => {})
        },

        goToSetRange() {
            if (!Object.keys(this.rangeForSend).length) {
                this.setDefaultRange()
                return
            }

            const { range, preset } = this.rangeForSend

            const findPreset = this.presets.find((p) => p.key === preset)

            if (findPreset && preset !== 'customRange') {
                this.range = findPreset.range
                this.rangeForSend.range = findPreset.range

                setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.leads)
                this.saveQuery()
            } else {
                this.range = range
            }
        },

        getQueryRange() {
            const { range = [], preset } = getQuery()

            if (range.length) {
                this.rangeForSend = { range, preset }
                setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.leads)
            }
        },

        getLocalStorageRange() {
            const savedRange = getRangeLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.leads)

            if (savedRange && Object.keys(savedRange).length) {
                this.rangeForSend = savedRange
                this.saveQuery()
            }
        },
    },
})
