function checkUrl(url) {
    return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)
}

function getFormatLinkText(message) {
    const rows = message.split('\n')
    const results = rows.map((textRow) => {
        if (checkUrl(textRow)) {
            const res = textRow.split(' ').map((text) => {
                if (checkUrl(text)) {
                    return `<a target="_blank" href="${text}" class="text-purple dark:text-purple-140">${text}</a>`
                }
                return text
            })
            return res.join(' ')
        }
        return textRow
    })
    return results.join('\n')
}

export {
    getFormatLinkText,
}
