import { ref, onMounted, onUnmounted } from 'vue'

import socket from '@/plugins/socket'

import { useNotificationsStore } from '@/store/notificaitonsStore'
import { useAppStore } from '@/store/appStore'

export function useSocket() {
    const appStore = useAppStore()
    const notificationStore = useNotificationsStore()

    const isConnected = ref(false)
    const connectRoom = ref(null)

    function connectToRoom(room) {
        connectRoom.value = room
        socket.emit('joinRoom', { room: connectRoom.value })
    }

    const sendMessage = (event, payload) => {
        socket.emit(event, payload)
    }

    function disconnectRoom() {
        if (connectRoom.value) {
            socket.emit('leaveRoom', { room: connectRoom.value })

            connectRoom.value = null
        }
    }

    onMounted(() => {
        socket.on('connect', () => {
            isConnected.value = true
        })

        socket.on('send_notification', (data) => {
            notificationStore.addNotification(data)
        })

        socket.on('send_maintenance_mode', (data) => {
            appStore.setMaintenanceMode(data)
        })
    })

    onUnmounted(() => {
        disconnectRoom()
        socket.disconnect()
    })

    return {
        isConnected,
        sendMessage,
        connectRoom: connectRoom.value,
        connectToRoom,
        disconnectRoom,
    }
}
