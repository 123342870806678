import { defineStore } from 'pinia'

import {
    getDetailTrafficSource,
    updateTrafficSource,
    deleteTrafficSourceParameter,
    getTrafficSourceEventsAll,
    updateStatusTrafficSourcesParameter,
} from '@/api/call-tracking/traffic-sources/traffic-sources-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { usePermissionsStore } from '@/store/permissionsStore'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { TRAFFIC_SOURCES_PARAMETERS_HEADERS, TRAFFIC_SOURCES_PARAMETERS_HEADERS_WITH_ACTIONS } from '@/constants/headersTable/trafficSourcesParametersHeadersTable'
import { FILTER_ACTIVE, FILTER_NAME, FILTER_URL_PARAM } from '@/constants/localFilters'
import { parseParams, updateParams } from '@/helpers/destinations/destination-request-helper'

export const useDetailTrafficSourceStore = defineStore('detailTrafficSource', {
    state: () => ({
        slug: null,
        id: null,
        loading: false,
        updateLoading: false,
        actionLoading: false,

        formData: {
            name: null,
            event_id: 1,
            postback_url: null,
            facebook_events: [],
        },

        integration: null,

        search: '',
        selected: [],
        events: [],
        parameters: [],
        postback_request_params: [],

        parametersFilters: [
            FILTER_NAME,
            FILTER_URL_PARAM,
            FILTER_ACTIVE,
        ],
    }),

    getters: {
        headersByPermission() {
            const permissionsStore = usePermissionsStore()

            return permissionsStore.permissions.trafficSources.edit ? TRAFFIC_SOURCES_PARAMETERS_HEADERS_WITH_ACTIONS : TRAFFIC_SOURCES_PARAMETERS_HEADERS
        },
    },

    actions: {
        updateIntegration(integration) {
            this.integration = integration
        },

        getQueryLocalStorage() {
            const localFilterTableStore = useLocalFilterTableStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.trafficSourceParameters, this.parametersFilters)
            localFilterTableStore.setHeaders(SETTINGS_HEADER_TABLE.trafficSourceParameters, this.headersByPermission)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.trafficSourceParameters, this.id)
        },

        setTrafficSourceSlug(slug) {
            this.slug = slug
        },

        addParameter(newParameter) {
            this.parameters.push(newParameter)
        },

        _changeParametersAfterDelete(ids) {
            this.parameters = this.parameters.filter((i) => !ids.includes(i.id))
        },

        _changeManyParametersAfterUpdate(payload) {
            payload.forEach((item) => {
                const index = this.parameters.findIndex((i) => i.id === item.id)
                if (index !== -1) {
                    this.parameters.splice(index, 1, item)
                }
            })
        },

        parsePayload({ postback_request_params, ts }) {
            this.postback_request_params = parseParams(postback_request_params)
            this.parsePayloadTs(ts)
        },

        parsePayloadTs({ integration, url_params = [], ...other }) {
            this.id = other.id

            this.formData = {
                ...other,
                event_id: other.event_id ?? this.formData.event_id,
            }

            this.parameters = url_params

            this.integration = integration || null
        },

        updateCustomParams() {
            this.postback_request_params = updateParams(this.postback_request_params)
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        async goToLoadDetailTrafficSource(withoutLoading = false) {
            const defaultStore = useDefaultStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getDetailTrafficSource(this.slug)

            if (success) {
                this.parsePayload(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false

            return success
        },

        async goToUpdateTrafficSource(formDataForUpdate) {
            const defaultStore = useDefaultStore()

            this.updateLoading = true

            const formDataForSend = {
                ...this.formData,
                ...formDataForUpdate,
            }

            const { success, payload, message } = await updateTrafficSource({
                formData: formDataForSend,
                slug: this.slug,
            })

            if (success) {
                this.parsePayloadTs({ ...formDataForSend, integration: payload.integration, url_params: this.parameters })

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.updateLoading = false
        },

        async goToDeleteTrafficSourceParameter({ trafficSources = [], trafficSource = null }) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formData = {}

            if (trafficSource) {
                formData.ids = [trafficSource.id]
            } else {
                formData.ids = trafficSources.map(({ id }) => id)
            }

            const { success, message } = await deleteTrafficSourceParameter({
                slug: this.slug,
                data: formData,
            })

            if (success) {
                this._changeParametersAfterDelete(formData.ids)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        clearSelected() {
            this.selected = []
        },

        async goToChangeStatusActiveTrafficSourceParameter({ trafficSource = null, trafficSources = [], status_id }) {
            const defaultStore = useDefaultStore()

            this.updateLoading = true

            const formData = {}

            if (trafficSource) {
                formData.ids = [trafficSource.id]
            } else {
                formData.ids = trafficSources.map(({ id }) => id)
            }
            formData.active = status_id

            const { success, payload, message } = await updateStatusTrafficSourcesParameter({
                slug: this.slug,
                formData,
            })

            if (success) {
                this._changeManyParametersAfterUpdate(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.updateLoading = false

            return success
        },

        async goToLoadTrafficSourceEventsAll() {
            if (this.events.length) {
                return
            }

            const { success, payload, message } = await getTrafficSourceEventsAll()

            if (success) {
                this.events = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        changeSearch(value) {
            this.search = value
        },
    },
})
