import { defineStore } from 'pinia'

import {
    getAvailableUsers, attachUsers, detachUsers,
} from '@/api/call-tracking/campaign/campaign-actions-routes'

import { uniqArrayByField } from '@/helpers/app-helper'

import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { DETAIL_CAMPAIGN_ACCESS_HEADERS } from '@/constants/headersTable/detailCampaignAccessHeadersTable'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_EMAIL, FILTER_ROLE } from '@/constants/localFilters'

export const useDetailCampaignAccessStore = defineStore('detailCampaignAccess', {
    state: () => ({
        loading: false,
        attachLoading: false,
        actionLoading: false,

        filters: [],

        availableUsers: [],

        selectedUsers: [],

        users: [],
    }),

    actions: {
        async getQueryLocalStorage() {
            await this.goToLoadFilterLocal()

            const localFilterTableStore = useLocalFilterTableStore()
            const detailCampaignStore = useDetailCampaignStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.campaignAccess, this.filters)
            localFilterTableStore.setHeaders(SETTINGS_HEADER_TABLE.campaignAccess, DETAIL_CAMPAIGN_ACCESS_HEADERS)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.campaignAccess, detailCampaignStore.id)
        },

        setMembers(payload) {
            const authorizationStore = useAuthorizationStore()

            this.users = payload.users.filter((user) => user.email !== authorizationStore.user.email).map((user) => ({
                ...user,
                role: user.roles[0].name,
            }))
        },

        async goToLoadAvailableUsers() {
            const defaultStore = useDefaultStore()
            const detailCampaignStore = useDetailCampaignStore()

            this.loading = true

            const { success, payload, message } = await getAvailableUsers(detailCampaignStore.slug)

            if (success) {
                payload.forEach((user) => {
                    user.role = user.roles[0].name
                })

                this.availableUsers = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        goToLoadFilterLocal() {
            this.filters = [
                FILTER_EMAIL,
                {
                    ...FILTER_ROLE,
                    values: uniqArrayByField(
                        this.users.map((item) => ({ name: item.role, id: item.role })),
                        'id',
                    ),
                },
            ]
        },

        selectUsers(users) {
            this.selectedUsers = users
        },

        async goToAttachUsers() {
            const defaultStore = useDefaultStore()
            const detailCampaignStore = useDetailCampaignStore()

            this.attachLoading = true

            const formData = {
                emails: this.selectedUsers.map((user) => user.email),
            }

            const { success, payload, message } = await attachUsers({
                formData,
                slug: detailCampaignStore.slug,
            })

            if (success) {
                this.setMembers(payload)

                await this.goToLoadAvailableUsers()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.attachLoading = false
        },

        async goToDetachUser(emails) {
            const defaultStore = useDefaultStore()
            const detailCampaignStore = useDetailCampaignStore()

            this.actionLoading = true

            const { success, payload, message } = await detachUsers({
                emails,
                slug: detailCampaignStore.slug,
            })

            if (success) {
                this.setMembers(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },
    },
})
