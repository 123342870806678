<template>
    <CustomMenu
        v-model="menu"
        hideTransitionSlider
    >
        <template #activator>
            <div>
                <div
                    class="relative cursor-pointer"
                    @click="menuHandler"
                >
                    <div
                        v-if="unreadNotifications.length"
                        class="absolute top-1 left-4.5 w-2 h-2 rounded-full"
                    >
                        <div class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red opacity-75"></div>

                        <div class="w-2 h-2 rounded-full bg-red"></div>
                    </div>

                    <div
                        class="rounded-1.25 transition-all hover:bg-purple-50 dark:hover:bg-blue-650"
                        :class="[smallNotificationMenu || menu ? 'bg-purple-50 dark:bg-blue-650' : 'bg-purple-70 dark:bg-blue-770']"
                    >
                        <NotificationIcon
                            class="cursor-pointer transition-all"
                            :class="[unreadNotifications.length ? 'text-gray-370 dark:text-white' : 'text-gray-200']"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template #dropdown>
            <div
                class="max-mob:w-full w-102.5 pt-5 fixed shadow-notifications bg-white dark:bg-blue-840 border border-gray-100 dark:border-blue-720 rounded-custom"
                ref="dropdownRef"
                :style="position"
                @click.stop
            >
                <div class="flex items-center justify-between mx-5 mb-5 pb-4 border-b border-gray-100 dark:border-blue-720">
                    <div class="text-lg font-medium text-gray-370 dark:text-white">
                        NOTIFICATIONS
                    </div>

                    <div
                        v-if="isNotifications"
                        class="text-xs text-purple"
                        :class="loadingRead ? 'cursor-wait' : 'cursor-pointer'"
                        @click="onRead(null)"
                    >
                        Mark all as read
                    </div>
                </div>

                <NotificationsList
                    v-if="isNotifications"
                    :loadingReadIds="loadingReadIds"
                    :notifications="notifications"
                    @onRead="onRead"
                />

                <div
                    v-else
                    class="h-17 flex justify-center items-center text-xs text-gray-280 pb-5"
                >
                    You don’t have new notifications
                </div>
            </div>
        </template>
    </CustomMenu>
</template>

<script setup>
import {
    computed, inject, ref,
} from 'vue'

import CustomMenu from '@/components/menu/CustomMenu/CustomMenu.vue'
import NotificationsList from '@/components/lists/NotificationsList.vue'
import NotificationIcon from '@/components/icons/NotificationIcon.vue'

import { viewPorts } from '@/constants/viewPorts'

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    smallNotificationMenu: {
        type: Boolean,
        default: false,
    },
    unreadNotifications: {
        type: Array,
        default: () => [],
    },
    readNotifications: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    loadingRead: {
        type: Boolean,
        default: false,
    },
    loadingReadIds: {
        type: Object,
        default: () => ({}),
    },
})

const emits = defineEmits(['onRead', 'update:modelValue'])

const currentViewSize = inject('currentViewSize')

const menu = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    },
})

const dropdownRef = ref(null)

const isMobView = computed(() => viewPorts.mob >= currentViewSize.value)

const notifications = computed(() => [...props.unreadNotifications, ...props.readNotifications])

const position = computed(() => {
    if (!dropdownRef.value) {
        return
    }

    const elem = dropdownRef.value.getBoundingClientRect()
    const left = window.innerWidth - elem.width

    return {
        left: isMobView.value ? '0' : `${left - 20}px`,
        top: isMobView.value ? 'h-app-bar' : '48px',
    }
})

const isNotifications = computed(() => notifications.value.length)

function menuHandler() {
    if (props.loading) {
        return
    }

    menu.value = !menu.value
}

function onRead(slug) {
    emits('onRead', slug)
}
</script>
