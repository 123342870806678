import { defineStore } from 'pinia'

import { getAvailableIvr, getDetailCampaign, updateCampaign } from '@/api/call-tracking/campaign/campaigns-routes'
import { attachDestinationToMenu } from '@/api/call-tracking/campaign/campaign-actions-routes'

import { getArrayOfUniqueValues } from '@/helpers/app-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'
import { useDetailCampaignTrackingNumberStore } from '@/store/call-tracking/campaigns/detailCampaignTrackingNumberStore'
import { useDetailCampaignNumberPoolsStore } from '@/store/call-tracking/campaigns/detailCampaignNumberPoolsStore'
import { useDetailNumberPoolTrafficSourcesStore } from '@/store/call-tracking/numberPools/detailNumberPoolTrafficSourcesStore'
import { useDetailCampaignDestinationStore } from '@/store/call-tracking/campaigns/detailCampaignDestinationStore'
import { useDetailCampaignGroupDestinationStore } from '@/store/call-tracking/campaigns/detailCampaignGroupDestinationStore'
import { useDetailCampaignFilterStore } from '@/store/call-tracking/campaigns/detailCampaignFilterStore'
import { useDetailCampaignAccessStore } from '@/store/call-tracking/campaigns/detailCampaignAccessStore'
import { useDetailCampaignTrackingNumberTableStore } from '@/store/call-tracking/campaigns/detailCampaignTrackingNumberTable'
import { useDetailTrafficSourceStore } from '@/store/call-tracking/trafficSources/detailTrafficSourceStore'
import { usePermissionsStore } from '@/store/permissionsStore'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { VIEWS_OPTIONS } from '@/constants/call-tracking/campaign/campaignSettingsOptions'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FORWARDS_CALL_TO_HEADERS_TABLE } from '@/constants/headersTable/forwardsCallToHeadersTable'
import { ROUTING_OPTIONS } from '@/constants/campaignRoutingOptions'
import { ROUTES } from '@/constants/routes'
import { FILTER_DESTINATION, FILTER_NAME, FILTER_STATUS_CUSTOM } from '@/constants/localFilters'
import { BC_ACTIONS } from '@/constants/breadCrumbsActions'

export const useDetailCampaignStore = defineStore('detailCampaign', {
    state: () => ({
        slug: null,
        id: null,
        currentView: VIEWS_OPTIONS.setupCampaign,
        loading: true,
        isReloadDetailCampaignBlocked: false,
        updateLoading: false,
        actionLoading: false,
        ivrLoading: false,
        liveLoading: false,
        fullDetailData: null,
        formData: {
            name: null,
            country_id: null,
            status_id: null,

            public: 1,

            ivr_id: null,
            routing_type_id: null,
            dial_attempts: null,
            duplicate_route_id: null,
            duplicates_strict: false,
            min_revenue: null,
            max_revenue: null,

            queue_enabled: false,
            queue_audio: null,
            queue_time_limit: null,

            record_calls: null,

            spam_filter: null,
            filter_anonymous: null,
            spam_time: null,

            filter_enabled: null,

            voip_shield: null,
            voip_black_list_id: null,

            default_vendor_payout_option: null,
            default_vendor_payout_on_option_id: null,
            default_vendor_duplicate_option_id: null,
            default_vendor_length_option: null,
            default_vendor_time_limit_option: null,

            menu_message_type_id: null,
            menu_message_url: null,
            menu_message_text: null,
            menu_message_language: null,
            menu_message_voice: null,

            schedule_enabled: null,
            timezone_id: null,
            schedule: null,
            schedule_voicemail_enabled: null,
            schedule_voicemail_transcription: null,
            schedule_message_type_id: null,
            schedule_message_url: null,
            schedule_message_text: null,
            schedule_message_language: null,
            schedule_message_voice: null,

            greeting_message_enabled: null,
            greeting_message_type_id: null,
            greeting_message_url: null,
            greeting_message_text: null,
            greeting_message_language: null,
            greeting_message_voice: null,

            whisper_message_enabled: null,
            whisper_message_type_id: null,
            whisper_message_url: null,
            whisper_message_text: null,
            whisper_message_language: null,
            whisper_message_voice: null,

            voicemail_message_enabled: null,
            voicemail_voicemail_transcription: null,
            voicemail_message_type_id: null,
            voicemail_message_url: null,
            voicemail_message_text: null,
            voicemail_message_language: null,
            voicemail_message_voice: null,

            sms_reply_enabled: null,
            sms_reply_during_schedule_incoming: null,
            sms_reply_during_schedule_missed_voicemail_enabled: null,
            sms_reply_during_schedule_missed_voicemail_disabled: null,
            sms_reply_after_schedule_incoming: null,
            sms_reply_after_schedule_missed_voicemail_enabled: null,
            sms_reply_after_schedule_missed_voicemail_disabled: null,

            max_concurrency_enabled: false,
            max_concurrency: null,

            cap_enabled: false,
            cap_on_id: null,
            global_cap: null,
            monthly_cap: null,
            daily_cap: null,
            hourly_cap: null,
        },

        availableIvr: [],

        dialogChooseNumber: false,

        currentDigit: null,

        callForwardsFilters: [
            FILTER_NAME,
            FILTER_DESTINATION,
            FILTER_STATUS_CUSTOM,
        ],

        callFilterEditMode: false,
        callFilterUnchanged: true,
        showConfirmDialogBeforeChangeView: false,

        nextViewSettings: {
            view: null,
            actinName: null,
            BCSettings: null,
            qnt: 1,
        },
    }),

    getters: {
        destinationsForCounters(state) {
            switch (state.formData.routing_type_id) {
            case ROUTING_OPTIONS.standard.id:
            case ROUTING_OPTIONS.revenue.id:
                return state.fullDetailData ? [
                    ...state.fullDetailData.destinations,
                    ...state.fullDetailData.destination_groups.flatMap((item) => item.destinations),
                ] : []

            case ROUTING_OPTIONS.menu.id:
                return state.fullDetailData ? [
                    ...state.fullDetailData.menu.destinations,
                    ...state.fullDetailData.menu.destination_groups.flatMap((item) => item.destinations),
                ] : []

            default: return []
            }
        },

        destinationsSlugsForCounters() {
            const attachedDestinations = this.destinationsForCounters.map((destination) => destination.slug)

            return getArrayOfUniqueValues(attachedDestinations)
        },

        buyersSlugsForCounters() {
            const attachedBuyers = this.destinationsForCounters.reduce((buyersSlugs, destination) => {
                if (destination.buyer) {
                    buyersSlugs.push(destination.buyer.slug)
                }

                return buyersSlugs
            }, [])

            return getArrayOfUniqueValues(attachedBuyers)
        },

        callForwardsControlsParams(state) {
            return {
                filters: state.callForwardsFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },
    },

    actions: {
        setCampaignSlug(campaignSlug) {
            this.slug = campaignSlug
        },

        getQueryLocalStorage() {
            const localFilterTableStore = useLocalFilterTableStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.forwardCallsTo, this.callForwardsFilters)
            localFilterTableStore.setHeaders(SETTINGS_HEADER_TABLE.forwardCallsTo, FORWARDS_CALL_TO_HEADERS_TABLE)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.forwardCallsTo, this.id)
        },

        blockReloadDetailCampaign() {
            this.isReloadDetailCampaignBlocked = true
        },

        async goToLoadDetailCampaign() {
            if (this.isReloadDetailCampaignBlocked) {
                this.isReloadDetailCampaignBlocked = false

                return
            }

            const defaultStore = useDefaultStore()
            const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()

            this.loading = true

            const { success, payload, message } = await getDetailCampaign(this.slug)

            if (success) {
                this.parseProps(payload)
                this.setCallFilterGroupsToStore(payload)

                detailCampaignTrackingNumberStore.setAttachedNumbersAndVendors(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false

            return { success, payload }
        },

        setAttachedNumbersAndVendors({ numbers = [], vendors = [], pools = [] }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()

            const parseNumbers = detailCampaignTrackingNumberTableStore.parseNumbers({ numbers })
            const parseNumberPools = detailCampaignTrackingNumberTableStore.parseNumberPools({ phone_number_pools: pools })

            detailCampaignTrackingNumberTableStore.setNumbers(parseNumbers)
            detailCampaignTrackingNumberTableStore.setNumberPools(parseNumberPools)
            detailCampaignTrackingNumberTableStore.setVendors(vendors)
        },

        async goToLoadAvailableIVR() {
            const permissionsSore = usePermissionsStore()

            if (!permissionsSore.permissions.callFlows.view) {
                return
            }

            const defaultStore = useDefaultStore()
            this.ivrLoading = true

            const { success, payload, message } = await getAvailableIvr(this.slug)

            if (success) {
                this.availableIvr = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.ivrLoading = false
        },

        parseProps(payload) {
            const detailCampaignTrackingNumberTable = useDetailCampaignTrackingNumberTableStore()
            const detailCampaignDestinationStore = useDetailCampaignDestinationStore()
            const detailCampaignGroupDestinationStore = useDetailCampaignGroupDestinationStore()
            const detailCampaignAccessStore = useDetailCampaignAccessStore()

            this.id = payload.id

            Object.keys(this.formData).forEach((name) => {
                if (!payload.hasOwnProperty(name)) {
                    return
                }
                this.formData[name] = payload[name]
            })

            if (!this.fullDetailData) {
                this.fullDetailData = payload
            } else {
                Object.keys(this.fullDetailData).forEach((name) => {
                    if (!payload.hasOwnProperty(name)) {
                        return
                    }

                    this.fullDetailData[name] = payload[name]
                })
            }

            detailCampaignGroupDestinationStore.setGroupDestination({
                ...payload,
                destination_groups: payload.destination_groups.map((g) => ({ ...g, isGroup: true })),
            })

            detailCampaignDestinationStore.setDestinations(payload)

            const numbers = detailCampaignTrackingNumberTable.parseNumbers(payload)
            const numberPools = detailCampaignTrackingNumberTable.parseNumberPools(payload)

            detailCampaignTrackingNumberTable.setVendors(payload.vendors)
            detailCampaignTrackingNumberTable.setNumbers(numbers)
            detailCampaignTrackingNumberTable.setNumberPools(numberPools)
            detailCampaignAccessStore.setMembers(payload)
        },

        setCallFilterGroupsToStore(payload) {
            const detailCampaignFilterStore = useDetailCampaignFilterStore()

            if (payload.hasOwnProperty('call_filter') && payload.call_filter.hasOwnProperty('groups')) {
                detailCampaignFilterStore.setCallFilterGroups(payload.call_filter.groups)
            } else {
                detailCampaignFilterStore.setCallFilterGroups([])
            }
        },

        async goToUpdateCampaign(formDataForUpdate) {
            const defaultStore = useDefaultStore()
            const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()

            this.updateLoading = true

            const formDataForSend = { ...this.formData, ...formDataForUpdate }

            const { success, payload, message } = await updateCampaign({
                formData: formDataForSend,
                slug: this.slug,
            })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.parseProps(payload)
                detailCampaignTrackingNumberStore.setAttachedNumbersAndVendors(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.updateLoading = false

            return { success }
        },

        async goToUpdateLiveTrackingNumber(item, value) {
            const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()
            detailCampaignTrackingNumberStore.setTrackingNumber(item)

            this.liveLoading = item.slug

            await detailCampaignTrackingNumberStore.goToUpdateTrackingNumber({
                max_concurrency: value,
            }, this.slug)
        },

        setAttachedDestinations(destinations = []) {
            this.fullDetailData.destinations.unshift(...destinations)
        },

        setAttachedGroups(groups = []) {
            this.fullDetailData.destination_groups.push(...groups)
        },

        async goToAttachDestinationToMenu(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await attachDestinationToMenu({ formData, slug: this.slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this._addDestinationToMenu(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        setCurrentView(item) {
            if (item.hasOwnProperty('view')) {
                this.currentView = item.view

                return
            }

            if (item.hasOwnProperty('routeName')) {
                this.router.push({ name: item.routeName })
            }
        },

        applyViewChange() {
            const breadCrumbsStore = useBreadCrumbsStore()

            if (!this.callFilterUnchanged) {
                this.setConfirmDialogBeforeChangeView(true)

                return
            }

            this.currentView = this.nextViewSettings.view

            breadCrumbsStore[this.nextViewSettings.actinName](this.nextViewSettings.BCSettings, this.nextViewSettings.qnt)
        },

        setConfirmDialogBeforeChangeView(value) {
            this.showConfirmDialogBeforeChangeView = value
        },

        openSetupTab(view) {
            this.nextViewSettings.view = view
            this.nextViewSettings.actinName = BC_ACTIONS.setBreadCrumbs
            this.nextViewSettings.BCSettings = [
                { title: 'Manage Campaigns', routeName: ROUTES.manageCampaigns },
                { title: 'Setup Campaign', view },
            ]
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAddUsersToCampaign() {
            this.nextViewSettings.view = VIEWS_OPTIONS.addUsersToCampaign
            this.nextViewSettings.actinName = BC_ACTIONS.setBreadCrumbs
            this.nextViewSettings.BCSettings = [
                { title: 'Manage Campaigns', routeName: ROUTES.manageCampaigns },
                { title: 'Setup Campaign', view: VIEWS_OPTIONS.accessCampaign },
                { title: 'Add Users', view: VIEWS_OPTIONS.addUsersToCampaign },
            ]
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        actionDialogChooseNumber(value) {
            this.dialogChooseNumber = value
        },

        openAddTrackingNumbers() {
            const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()

            detailCampaignTrackingNumberStore.goToLoadAvailableNumbers(this.slug)

            this.nextViewSettings.view = VIEWS_OPTIONS.addTrackingNumbers
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Tracking Numbers', view: VIEWS_OPTIONS.addTrackingNumbers }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAddNumberPools() {
            const detailCampaignNumberPoolsStore = useDetailCampaignNumberPoolsStore()

            detailCampaignNumberPoolsStore.goToLoadAvailableNumberPools(this.slug)

            this.nextViewSettings.view = VIEWS_OPTIONS.addNumberPools
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Number Pools', view: VIEWS_OPTIONS.addNumberPools }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openBuyNumbers() {
            this.nextViewSettings.view = VIEWS_OPTIONS.buyTrackingNumbers
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Buy Tracking Numbers', view: VIEWS_OPTIONS.buyTrackingNumbers }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openBuyNumbersFromTable() {
            this.nextViewSettings.view = VIEWS_OPTIONS.buyTrackingNumbers
            this.nextViewSettings.actinName = BC_ACTIONS.setBreadCrumbs
            this.nextViewSettings.BCSettings = [
                { title: 'Manage Campaigns', routeName: ROUTES.manageCampaigns },
                { title: 'Setup Campaign', view: VIEWS_OPTIONS.setupCampaign },
                { title: 'Buy Tracking Numbers', view: VIEWS_OPTIONS.buyTrackingNumbers },
            ]
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openDetailNumberPool() {
            this.nextViewSettings.view = VIEWS_OPTIONS.detailNumberPool
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Detail Number Pool', view: VIEWS_OPTIONS.detailNumberPool }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAddTrackingNumbersToPool() {
            const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()

            detailCampaignTrackingNumberStore.goToLoadAvailableNumbers(this.slug)

            this.nextViewSettings.view = VIEWS_OPTIONS.addTrackingNumbersToNumberPool
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Numbers to Pool', view: VIEWS_OPTIONS.addTrackingNumbersToNumberPool }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAddTrafficSourceToPool() {
            const detailNumberPoolTrafficSourcesStore = useDetailNumberPoolTrafficSourcesStore()

            detailNumberPoolTrafficSourcesStore.goToLoadAvailableTrafficSources()

            this.nextViewSettings.view = VIEWS_OPTIONS.addTrafficSourceToPool
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Numbers to Pool', view: VIEWS_OPTIONS.addTrackingNumbersToNumberPool }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAttachTrafficSourceToPool() {
            const detailTrafficSourceStore = useDetailTrafficSourceStore()

            detailTrafficSourceStore.getQueryLocalStorage()
            detailTrafficSourceStore.goToLoadDetailTrafficSource()

            this.nextViewSettings.view = VIEWS_OPTIONS.attachTrafficSourceToPool
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Setup Traffic Source', view: VIEWS_OPTIONS.attachTrafficSourceToPool }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAttachTrafficSourceToTrackingNumber() {
            const detailTrafficSourceStore = useDetailTrafficSourceStore()

            detailTrafficSourceStore.getQueryLocalStorage()
            detailTrafficSourceStore.goToLoadDetailTrafficSource()

            this.nextViewSettings.view = VIEWS_OPTIONS.attachTrafficSourceToTrackingNumber
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Setup Traffic Source', view: VIEWS_OPTIONS.attachTrafficSourceToTrackingNumber }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openBuyTrackingNumbersToPool() {
            this.nextViewSettings.view = VIEWS_OPTIONS.buyTrackingNumbersToNumberPool
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Numbers to Pool', view: VIEWS_OPTIONS.buyTrackingNumbersToNumberPool }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openDetailTrafficSourceInPool() {
            const detailTrafficSourceStore = useDetailTrafficSourceStore()

            detailTrafficSourceStore.getQueryLocalStorage()
            detailTrafficSourceStore.goToLoadDetailTrafficSource()

            this.nextViewSettings.view = VIEWS_OPTIONS.detailTrafficSourceInPool
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Detail Traffic Source', view: VIEWS_OPTIONS.detailTrafficSourceInPool }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAttachVendorToPool() {
            this.nextViewSettings.view = VIEWS_OPTIONS.attachVendorToPool
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Setup Vendor', view: VIEWS_OPTIONS.attachVendorToPool }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openDetailTrafficSourceInTrackingNumber() {
            const detailTrafficSourceStore = useDetailTrafficSourceStore()

            detailTrafficSourceStore.getQueryLocalStorage()
            detailTrafficSourceStore.goToLoadDetailTrafficSource()

            this.nextViewSettings.view = VIEWS_OPTIONS.detailTrafficSourceInTrackingNumber
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Detail Traffic Source', view: VIEWS_OPTIONS.detailTrafficSourceInTrackingNumber }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openTrackingNumberSettings(number) {
            const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()

            detailCampaignTrackingNumberStore.setTrackingNumber(number)

            this.nextViewSettings.view = VIEWS_OPTIONS.detailTrackingNumber
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Setup Tracking Number', view: VIEWS_OPTIONS.detailTrackingNumber }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openTrackingNumberSettingsAfterBuy(number) {
            const detailCampaignTrackingNumberStore = useDetailCampaignTrackingNumberStore()

            detailCampaignTrackingNumberStore.setTrackingNumber(number)

            this.nextViewSettings.view = VIEWS_OPTIONS.detailTrackingNumberAfterBuy
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Setup Tracking Number', view: VIEWS_OPTIONS.detailTrackingNumberAfterBuy }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openSetupNewVendorView() {
            this.nextViewSettings.view = VIEWS_OPTIONS.setupNewVendor
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Setup Vendor', view: VIEWS_OPTIONS.setupNewVendor }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openDetailVendor() {
            this.nextViewSettings.view = VIEWS_OPTIONS.detailVendor
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Detail Vendor', view: VIEWS_OPTIONS.detailVendor }
            this.nextViewSettings.qnt = 2

            this.applyViewChange()
        },

        openAddDestinations() {
            const detailCampaignDestinationStore = useDetailCampaignDestinationStore()

            detailCampaignDestinationStore.goToLoadAvailableDestinations()

            this.nextViewSettings.view = VIEWS_OPTIONS.addDestinations
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Destinations', view: VIEWS_OPTIONS.addDestinations }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAddDestinationToMenu(digit) {
            const detailCampaignDestinationStore = useDetailCampaignDestinationStore()

            detailCampaignDestinationStore.goToLoadAvailableDestinations()

            this.setCurrentDigit(digit)

            this.nextViewSettings.view = VIEWS_OPTIONS.addDestinationToMenu
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Destination', view: VIEWS_OPTIONS.addDestinationToMenu }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        openAddGroupToMenu(digit) {
            const detailCampaignGroupDestinationStore = useDetailCampaignGroupDestinationStore()

            detailCampaignGroupDestinationStore.goToLoadAvailableGroups()

            this.setCurrentDigit(digit)

            this.nextViewSettings.view = VIEWS_OPTIONS.addGroupToMenu
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Group', view: VIEWS_OPTIONS.addGroupToMenu }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        setCurrentDigit(digit) {
            this.currentDigit = digit
        },

        openAddDestinationGroups() {
            const detailCampaignGroupDestinationStore = useDetailCampaignGroupDestinationStore()

            detailCampaignGroupDestinationStore.goToLoadAvailableGroups()

            this.nextViewSettings.view = VIEWS_OPTIONS.addDestinationGroups
            this.nextViewSettings.actinName = BC_ACTIONS.addItem
            this.nextViewSettings.BCSettings = { title: 'Add Destination Groups', view: VIEWS_OPTIONS.addDestinationGroups }
            this.nextViewSettings.qnt = 1

            this.applyViewChange()
        },

        changeCallFilterEditMode(value) {
            this.callFilterEditMode = value
        },

        setCallFilterUnchanged(value) {
            this.callFilterUnchanged = value
        },
    },
})
