<template>
    <div
        class="fixed flex flex-col justify-between bg-menu-white300 dark:bg-menu-black370 h-full transition-width custom-scroll-y"
        :class="[
            mini ? 'w-side-bar-main-mini' : 'w-side-bar-main',
            showMobileSideBars ? 'z-100' : '-left-offset-side-bar-main min-mb:left-0',
            maintenanceModeShow ? 'pb-7' : 'pb-5'
        ]"
        :style="{ 'max-height': `calc(100vh - ${height}px)` }"
        v-touch:swipe.left="swipeLeft"
    >
        <div>
            <SideBarWorkspaceMenu
                class="py-2 pl-2 pr-4"
                :mini="mini"
                :isOwner="isOwner"
                @onChange="changeMini"
            />

            <div
                v-if="mini && !isMbView"
                class="flex group w-6 h-6 mx-auto mb-2 items-center justify-center rounded-1.25 hover:bg-menu-purple70 dark:hover:bg-menu-blue770 cursor-pointer"
                @click="changeMini"
            >
                <DoubleArrowIcon class="text-gray-200 dark:text-gray-400 dark:group-hover:text-gray-200" />
            </div>

            <div class="flex flex-col gap-y-0.5 text-gray-200">
                <template
                    v-for="route in topMenu"
                    :key="route.id"
                >
                    <CustomRouterLink
                        class="relative h-9 px-4 flex items-center gap-x-1.25 cursor-pointer before:absolute before:right-0 before:top-0 before:h-0 before:w-0.75 before:bg-purple-140 transition-colors before:transition-all"
                        :class="menuStyle(route)"
                        :route="route"
                        :simpleRote="!!route.routeName"
                        :mini="mini"
                        @click="navigationActionHandler(route)"
                    >
                        <div class="w-5 flex item-center justify-center">
                            <component :is="route.icon()" />
                        </div>

                        <div
                            v-if="!mini"
                            class="text-center font-medium text-xs whitespace-nowrap"
                        >
                            {{ route.name }}
                        </div>
                    </CustomRouterLink>
                </template>
            </div>

            <CustomDivider
                :class="mini ? 'mx-2' : 'mx-4'"
                class="my-2"
                color="border-gray-100 dark:border-gray-760"
            />

            <RoutingSideBar
                :mini="mini"
                :showMobileSideBars="showMobileSideBars"
            />
        </div>

        <div>
            <CustomDivider
                v-if="authorizationStore.isWorkspaceOwner && !whiteLabelStore.whiteLabelIsEnabled"
                :class="mini ? 'mx-2' : 'mx-4'"
                class="my-2"
                color="border-gray-100 dark:border-gray-760"
            />

            <div
                v-if="!whiteLabelStore.whiteLabelIsEnabled"
                class="flex flex-col gap-y-0.5 text-gray-370 dark:text-gray-200"
                :class="[ mini && 'items-center' ]"
            >
                <template
                    v-for="route in BOTTOM_MENU_ACCOUNT"
                    :key="route.id"
                >
                    <CustomRouterLink
                        class="relative h-9 px-4 flex items-center gap-x-2 hover:bg-menu-purple70 dark:hover:bg-menu-blue770 transition-colors cursor-pointer"
                        :route="route"
                        :simpleRote="!!route.routeName"
                        :mini="mini"
                        @click="navigationActionHandler(route)"
                    >
                        <button
                            v-if="route.action === NAVIGATION_ACTIONS.last_changes"
                            data-featurebase-changelog
                            class="flex items-center"
                        >
                            <span class="w-5 flex item-center justify-center text-gray-200">
                                <component :is="route.icon()" />
                            </span>

                            <span
                                v-if="!mini"
                                class="pl-2 text-center text-xs font-medium whitespace-nowrap text-gray-200"
                            >
                                {{ route.name }}
                            </span>

                            <span id="fb-update-badge"></span>
                        </button>

                        <template v-else>
                            <div class="w-5 flex item-center justify-center text-gray-200">
                                <component :is="route.icon()" />
                            </div>

                            <div
                                v-if="!mini"
                                class="text-center text-xs font-medium whitespace-nowrap text-gray-200"
                            >
                                {{ route.name }}
                            </div>
                        </template>
                    </CustomRouterLink>
                </template>
            </div>

            <CustomDivider
                :class="mini ? 'mx-2' : 'mx-4'"
                class="mt-2 mb-4"
                color="border-gray-100 dark:border-gray-760"
            />

            <SideBarAccountMenu :mini="mini" />
        </div>

        <GlobalSearchDialog v-model="showSearchDialog" />
    </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue'
import { useRoute } from 'vue-router'

import GlobalSearchDialog from '@/components/dialogs/GlobalSearchDialog.vue'
import SideBarAccountMenu from '@/components/navigation/SideBar/SideBarAccountMenu.vue'
import CustomRouterLink from '@/components/navigation/CustomRouterLink.vue'
import SideBarWorkspaceMenu from '@/components/navigation/SideBar/SideBarWorkspaceMenu.vue'
import DoubleArrowIcon from '@/components/icons/arrow/DoubleArrowIcon.vue'
import RoutingSideBar from '@/components/navigation/RoutingSideBar/RoutingSideBar.vue'
import CustomDivider from '@/components/divider/CustomDivider.vue'

import { getDateDistanceToEndDateGettingStart } from '@/helpers/date-helper'
import { openFeedbackWidget } from '@/helpers/featurebase-helper'

import { useAuthorizationStore } from '@/store/authorizationStore'
import { useWhiteLabelStore } from '@/store/call-tracking/whiteLabelStore'
import { useAppStore } from '@/store/appStore'

import {
    GETTING_START, TOP_MENU_ACCOUNT_FOR_OWNER, TOP_MENU_ACCOUNT_FOR_MANAGER, BOTTOM_MENU_ACCOUNT,
} from '@/constants/accountMenu'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'
import { viewPorts } from '@/constants/viewPorts'
import { MAINTENANCE_MODE_STATUS } from '@/constants/maintenanceMode'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    showMobileSideBars: {
        type: Boolean,
        default: false,
    },
    maintenanceModeHeight: {
        type: Number,
        default: 0,
    },
})

const emits = defineEmits(['update:mini', 'update:showMobileSideBars'])

const currentViewSize = inject('currentViewSize')

const authorizationStore = useAuthorizationStore()
const whiteLabelStore = useWhiteLabelStore()
const appStore = useAppStore()

const height = computed(() => props.maintenanceModeHeight)

const maintenanceModeShow = computed(() => appStore.maintenanceMode === MAINTENANCE_MODE_STATUS.active)

const route = useRoute()

const showSearchDialog = ref(false)

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

const isVendor = computed(() => authorizationStore.isVendor)
const isBuyer = computed(() => authorizationStore.isBuyer)
const isReporter = computed(() => authorizationStore.isReporter)
const isOwner = computed(() => authorizationStore.isOwner)

const isShowGettingStart = computed(() => {
    const { account } = authorizationStore

    if (account) {
        const { created_at } = account

        return getDateDistanceToEndDateGettingStart(created_at)
    }

    return false
})

const menuOwner = computed(() => {
    if (isShowGettingStart.value && !whiteLabelStore.whiteLabelIsEnabled) {
        return [...TOP_MENU_ACCOUNT_FOR_OWNER, GETTING_START]
    }

    return TOP_MENU_ACCOUNT_FOR_OWNER
})

const topMenu = computed(() => {
    if (authorizationStore.isWorkspaceOwner) {
        return menuOwner.value
    }

    if (!isReporter.value && !isVendor.value && !isBuyer.value) {
        return TOP_MENU_ACCOUNT_FOR_MANAGER
    }

    return [GETTING_START]
})

function navigationActionHandler(route) {
    if (!route.hasOwnProperty('action')) {
        return
    }

    switch (route.action) {
    case NAVIGATION_ACTIONS.search:
        openSearchDialog()
        break
    case NAVIGATION_ACTIONS.help:
        goToHelp()
        break
    case NAVIGATION_ACTIONS.feedback:
        openFeedbackWidget()
        break
    }
}

function checkCurrentLink(parent, subMenu) {
    const { routeName, isFirstLoad } = parent

    if (subMenu) {
        const isActive = subMenu.some((el) => el.routeName === route.meta.reference)

        if (isActive && isFirstLoad) {
            parent.isOpenSub = isActive
            parent.isFirstLoad = false
        }

        return isActive
    }
    return route.meta.reference === routeName
}

function menuStyle(route) {
    const { subMenu } = route

    const active = checkCurrentLink(route, subMenu)

    return active
        ? 'before:h-9 bg-menu-purple70 dark:bg-menu-blue770 border-purple dark:border-transparent text-purple dark:text-purple-140'
        : 'hover:bg-menu-purple70 hover:text-gray-370 dark:hover:text-white dark:hover:bg-menu-blue770'
}

function swipeLeft() {
    emits('update:showMobileSideBars', false)
}

function changeMini() {
    emits('update:mini', !props.mini)
}

function goToHelp() {
    window.open(`${import.meta.env.VITE_SITE_LINK}help-center/`, '_blank')
}

function openSearchDialog() {
    if (authorizationStore.isAccountReview) {
        return
    }

    showSearchDialog.value = !showSearchDialog.value
}
</script>
