const PARAMETERS_TYPES = {
    trafficSourceParameters: 'Traffic Source Parameters',
    callFlow: 'Call Flow',
    customParameters: 'Custom Parameters',
    callerInputParameters: 'Call Flow Parameters',
}

export {
    PARAMETERS_TYPES,
}
