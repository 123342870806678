import { defineStore } from 'pinia'

import {
    attachDestinations,
    attachDestinationToMenu,
    detachDestination,
    detachDestinationFromMenu,
    getAvailableDestinations,
    updateAttachedDestination,
    updateDestinationInMenu,
} from '@/api/call-tracking/campaign/campaign-actions-routes'

import { isOptionTypeEqualStatic } from '@/helpers/destinations/destination-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useDetailCampaignGroupDestinationStore } from '@/store/call-tracking/campaigns/detailCampaignGroupDestinationStore'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_STATUS } from '@/constants/localFilters'
import { ROUTING_OPTIONS } from '@/constants/campaignRoutingOptions'
import { STATUS_IDS } from '@/constants/statusIds'

export const useDetailCampaignDestinationStore = defineStore('detailCampaignDestinationStore', {
    state: () => ({
        loading: false,
        actionLoading: false,

        selectedDestinations: [],

        availableDestinations: [],
        availableDestinationsFilters: [
            FILTER_STATUS,
        ],

        menuDestinations: [],

        destinations: [],
    }),

    actions: {
        sortDestinationsByRevenue(a, b) {
            const revenue_a = this._getRevenue(a)
            const revenue_b = this._getRevenue(b)

            return revenue_b - revenue_a
        },

        _getRevenue(destination) {
            if (destination.hasOwnProperty('ping_config') && destination.ping_config) {
                return isOptionTypeEqualStatic(destination.ping_config.static_rate) ? destination.ping_config.static_revenue : 0
            }

            if (destination.hasOwnProperty('pivot') && destination.pivot) {
                return destination.pivot.revenue
            }

            return 0
        },

        setDestinations({ destinations, menu, routing_type_id }) {
            if (routing_type_id === ROUTING_OPTIONS.revenue.id) {
                const sortDestinations = destinations.sort((a, b) => this.sortDestinationsByRevenue(a, b))
                this.destinations = [
                    ...sortDestinations.filter((item) => item.status_id === STATUS_IDS.active),
                    ...sortDestinations.filter((item) => item.status_id === STATUS_IDS.noActive),
                ]
            } else {
                this.destinations = destinations
            }
            this.menuDestinations = menu.destinations
        },

        getQueryLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()
            const localFilterTableStore = useLocalFilterTableStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.campaignAvailableDestination, this.availableDestinationsFilters)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.campaignAvailableDestination, detailCampaignStore.id)
        },

        changeSelectedDestinations(newSelectedItems) {
            this.selectedDestinations = newSelectedItems
        },

        clearSelectedDestinations() {
            this.selectedDestinations = []
        },

        async goToUpdateDestinationToMenu(formData) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await updateDestinationInMenu({ formData, slug: detailCampaignStore.slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this._updateDestinationToMenu(payload, formData)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        _updateDestinationToMenu(destination, { old_digit }) {
            const foundIndex = this.menuDestinations.findIndex((d) => d.pivot.digit === old_digit)

            if (foundIndex + 1) {
                this.menuDestinations[foundIndex] = destination
            }
        },

        async goToUpdateAttachedDestination({ formData, destinationSlug }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await updateAttachedDestination({
                slug: detailCampaignStore.slug,
                formData,
                destinationSlug,
            })

            if (success) {
                this.updateDestinationAfterChange(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToDetachDestination({ destination = null, destinations = [] }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const formData = {
                slug: detailCampaignStore.slug,
            }

            if (destination) {
                formData.slugs = [destination.slug]
            } else {
                formData.slugs = destinations.map(({ slug }) => slug)
            }

            const { success, payload, message } = await detachDestination(formData)

            if (success) {
                this.removeDestination(formData.slugs)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success, payload }
        },

        removeDestination(slugs) {
            this.destinations = this.destinations.filter(({ slug }) => !slugs.find((item) => item === slug))
        },

        updateDestinationAfterChange(destination) {
            const index = this.destinations.findIndex((d) => d.slug === destination.slug)

            if (index + 1) {
                this.destinations[index] = destination
            }
        },

        async goToLoadAvailableDestinations(withoutLoading = false) {
            const defaultStore = useDefaultStore()
            const detailCampaignStore = useDetailCampaignStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getAvailableDestinations(detailCampaignStore.slug)

            if (success) {
                this.availableDestinations = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToAttachDestinationsToCampaign({ destinations = [], pivot }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            if (!destinations.length) {
                return
            }

            this.actionLoading = true

            const { success, payload, message } = await attachDestinations({
                formData: {
                    destinations: destinations.map((n) => n.slug),
                    ...pivot,
                },
                slug: detailCampaignStore.slug,
            })

            if (success) {
                this.setAttachedDestinations(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        updateDestinationStatus(destination) {
            const index = this.destinations.findIndex((d) => d.slug === destination.slug)

            if (index + 1) {
                this.destinations[index] = {
                    ...this.destinations[index],
                    status_id: destination.status_id,
                }
            }
        },

        updateDestinationsStatusesAfterChange(payload) {
            const detailCampaignGroupDestinationStore = useDetailCampaignGroupDestinationStore()

            payload.forEach((destination) => {
                const { pivot } = destination

                if (pivot && pivot.group_id) {
                    detailCampaignGroupDestinationStore.updateChildrenDestinationStatus(destination, pivot.group_id)
                } else {
                    this.updateDestinationStatus(destination)
                }
            })
        },

        setAttachedDestinations(destinations = []) {
            this.destinations.unshift(...destinations)
        },

        async goToAttachDestinationToMenu(formData) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await attachDestinationToMenu({ formData, slug: detailCampaignStore.slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this._addDestinationToMenu(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        _addDestinationToMenu(destination) {
            this.menuDestinations.push(destination)
        },

        async goToDetachDestinationFromMenu({ data, removeBlockAfterDelete = false }) {
            const detailCampaignStore = useDetailCampaignStore()
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await detachDestinationFromMenu({ data, slug: detailCampaignStore.slug })

            if (success) {
                defaultStore.setSuccessMessage({ message })

                this.removeDestinationFromMenu({ digit: data.digit, removeBlockAfterDelete })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        removeDestinationFromMenu({ digit, removeBlockAfterDelete = true }) {
            if (removeBlockAfterDelete) {
                this.menuDestinations = this.menuDestinations.filter((d) => d.pivot.digit.toString() !== digit.toString())
            } else {
                this.menuDestinations = this.menuDestinations.map((d) => {
                    if (d.pivot.digit === digit) {
                        d.removed = true
                    }

                    return d
                })
            }
        },

        updateMenuDestinationStatusAfterChange(payload) {
            const detailCampaignGroupDestinationStore = useDetailCampaignGroupDestinationStore()
            const [destination] = payload

            this.menuDestinations = this.updateMenuDestinationStatus(this.menuDestinations, destination)
            detailCampaignGroupDestinationStore.updateMenuChildrenDestinationStatus(destination)
        },

        updateMenuDestinationStatus(destinations, destination) {
            return destinations.map((d) => {
                if (d.slug === destination.slug) {
                    return {
                        ...d,
                        status_id: destination.status_id,
                    }
                }

                return d
            })
        },
    },
})
