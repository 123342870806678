<template>
    <CustomDialog v-model="show" hideCloseIcon hideHeader z-index="z-130">
        <form
            class="max-mob:px-4 px-7.5 py-5 max-mob:min-w-0 max-mob:w-full w-122.5"
            @keyup.enter="closeDialog"
            @submit.prevent
        >
            <div class="flex justify-center mb-10">
                <div v-if="!notification.image_url">
                    {{ notification.image_url }}
                    <UrgentlyNotificationIcon />
                </div>

                <div v-else>
                    <img :src="notification.image_url" :alt="notification.title">
                </div>
            </div>
            <div class="text-6.5 font-medium dark:text-white text-gray-370">
                {{ notification.title }}
            </div>

            <div
                v-html="getFormatLinkText(notification.message)"
                class="break-words whitespace-break-spaces mt-4 text-sm text-gray-280"
            />
        </form>

        <template #footer>
            <div class="flex items-center justify-end w-full gap-x-5">
                <PrimaryBtn
                    @click="closeDialog"
                >
                    Got it
                </PrimaryBtn>
            </div>
        </template>
    </CustomDialog>
</template>

<script setup>
import {
    computed, watch,
} from 'vue'

import CustomDialog from '@/components/dialogs/CustomDialog.vue'
import PrimaryBtn from '@/components/buttons/PrimaryBtn.vue'
import UrgentlyNotificationIcon from '@/components/icons/UrgentlyNotificationIcon.vue'

import { getFormatLinkText } from '@/helpers/text-helper'

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    notification: {
        type: Object,
        default: () => ({}),
    },
})

const emits = defineEmits(['update:modelValue', 'createdSuccessfully', 'onRead'])

const show = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    },
})

function closeDialog() {
    show.value = false
}

watch(show, (value) => {
    if (!value) {
        emits('onRead', props.notification.slug)
    }
})
</script>
