import { defineStore } from 'pinia'

import {
    getBlockedNumbers,
    unblockNumber,
    sendBlockedNumber,
    updateBlockedNumber,
    getBlockNumberFiltersAndSorting,
} from '@/api/block-numbers-routes'

import { getFilterOnPageInLocalStorageByWorkspaceId, setFilterOnPageInLocalStorageByWorkspaceId } from '@/helpers/save-sorting-local-storage-helper'
import { removeFirstPlusForSearch } from '@/helpers/app-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'

export const useBlockedNumbersStore = defineStore('blockedNumbers', {
    state: () => ({
        loading: false,
        actionLoading: false,
        allLoading: false,

        notNeedRequestChangePage: true,

        allBuyers: [],
        items: [],
        selected: [],

        page: 1,
        total: 1,
        onPage: 25,

        search: null,
    }),

    actions: {
        async goToLoadBlockNumberFiltersAndSorting() {
            const { success, payload, message } = await getBlockNumberFiltersAndSorting()

            if (success) {
                const sortFilterTableStore = useSortFilterTableStore()
                sortFilterTableStore.setFilters(SETTINGS_HEADER_TABLE.blockNumber, payload.filters)
                sortFilterTableStore.setSorting(SETTINGS_HEADER_TABLE.blockNumber, payload.sorting)
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToLoadBlockedNumbers() {
            const sortFilterTableStore = useSortFilterTableStore()
            const { filtersForSend, sortingForSend } = sortFilterTableStore.getTable(SETTINGS_HEADER_TABLE.blockNumber)

            this.clearSelected()

            this.loading = true

            this.notNeedRequestChangePage = false

            const { success, payload, message } = await getBlockedNumbers({
                page: this.page,
                search: removeFirstPlusForSearch(this.search),
                onPage: this.onPage,
                ...filtersForSend,
                ...sortingForSend,
            })

            if (success) {
                const { data = [], current_page, last_page } = payload
                this.items = data
                this.page = current_page
                this.total = last_page

                this.checkValidPage()
            } else {
                this.loading = false
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToSendBlockedNumber(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await sendBlockedNumber(formData)

            if (success) {
                this.goToLoadBlockedNumbers()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        checkValidPage() {
            if (this.page > this.total) {
                this.changePage(this.total)
            } else {
                this.loading = false
            }
        },

        async goToUnblockNumber(slugs) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await unblockNumber({ slugs })

            if (success) {
                this.goToLoadBlockedNumbers()
                this.checkValidPage()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToUpdateBlockedNumber({ slug, formData }) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await updateBlockedNumber({
                slug,
                formData,
            })

            if (success) {
                this._changeBlockedNumbersAfterUpdate(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        _changeBlockedNumbersAfterUpdate(number) {
            const index = this.items.findIndex((i) => i.slug === number.slug)

            if (index + 1) {
                this.items.splice(index, 1, number)
            }
        },

        onSearch(searchValue) {
            this.search = searchValue
            this.changePage(1)
        },

        changePage(newPage) {
            this.page = newPage

            if (!this.notNeedRequestChangePage) {
                this.goToLoadBlockedNumbers()
            }
        },

        changeOnPage(onPage) {
            this.onPage = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, SETTINGS_HEADER_TABLE.blockNumber)

            this.goToLoadBlockedNumbers()
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        clearSelected() {
            this.selected = []
        },

        getLocalStorageOnPage() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.blockNumber)

            this.onPage = savedOnPage ? Number(savedOnPage) : 25
        },
    },
})
