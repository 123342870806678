import { getRandomUUID } from '@/helpers/app-helper'

import { PARAMETERS_TYPES } from '@/constants/parametersTypes'

function joinParams(requestParams = []) {
    const filteredParams = requestParams.filter((param) => param.key || param.value)

    if (filteredParams.length === 0) {
        return ''
    }

    return (
        `?${filteredParams
            .map((param) => `${param.key}=${param.value || ''}`)
            .join('&')
        }`
    )
}

function parsePayloadItems(items) {
    return items.map((item) => ({
        key: item.key || '',
        value: item.value || '',
        test_value: item.test_value || '',
        uuid: getRandomUUID(),
        read: true,
    }))
}

function updateParams(params) {
    return params.map((item) => ({
        ...item,
        isOpen: false,
        items: item.items.map((el) => ({ ...el, isOpen: false })),
    }))
}

function parseParams(params) {
    return Object.keys(params).map((filterName) => ({
        label: filterName,
        isOpen: false,
        name: params[filterName].name,
        id: params[filterName].id,
        items: _createItemsParams(params, filterName),
    }))
}

function _createItemsParams(params, paramName) {
    switch (paramName) {
    case PARAMETERS_TYPES.trafficSourceParameters:
    case PARAMETERS_TYPES.callFlow:
        return _setThreeTierParametersItems(params[paramName])
    default:
        return _setAnotherParameterItems(params[paramName])
    }
}

function _setThreeTierParametersItems(parameters) {
    return Object.keys(parameters).map((paramName) => ({
        label: paramName,
        isOpen: false,
        items: _setAnotherParameterItems(parameters[paramName]),
    }))
}

function _setAnotherParameterItems(items) {
    return items.map((item) => ({
        ...item,
        name: item.key,
    }))
}

function parseOperators(operators) {
    return Object.keys(operators).map((operatorName) => ({
        name: operatorName,
        id: operators[operatorName].name,
    }))
}

function parseMultipleParamValue(value) {
    const match = value ? value.match(/\[([^\]]+)\]/) : ''

    if (match) {
        const foundValue = match[1]

        return foundValue.split('|')
    }

    return []
}

function updateMultipleValuesArray(valuesArray, value) {
    const foundIndex = valuesArray.findIndex((str) => str.toString().trim() === value.toString().trim())

    if (foundIndex + 1) {
        valuesArray.splice(foundIndex, 1)
    } else {
        valuesArray.push(value)
    }

    return valuesArray.length ? `[${valuesArray.join('|')}]` : ''
}

export {
    joinParams,
    parsePayloadItems,
    parseParams,
    updateParams,
    parseOperators,
    parseMultipleParamValue,
    updateMultipleValuesArray,
}
