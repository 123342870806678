import axios from '@/plugins/axios'

const getTimezones = () => axios.get('timezones')
const getCountries = () => axios.get('countries')
const getLanguages = () => axios.get('text-to-speech')

const getMaintenanceMode = () => axios.get('application-maintenance-mode')

export {
    getTimezones, getCountries, getLanguages, getMaintenanceMode,
}
