import { defineStore } from 'pinia'

import {
    exportAggregatedData,
    exportLogCSVData,
    generateLogXLSXData,
    getLogXLSXStatuses,
    downloadLogXLSXData,
} from '@/api/reporting-routes'

import { downloadFile } from '@/helpers/app-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { usePermissionsStore } from '@/store/permissionsStore'
import { useReportingStore } from '@/store/call-tracking/reportingStore'

import { exportType } from '@/constants/exportType'

export const useDownloadStore = defineStore('downloadStore', {
    state: () => ({
        showPopup: true,
        downloadingAggregate: false,
        downloadingAggregateStatus: null,
        downloadingLog: false,
        downloadingLogStatus: null,
        aggregateFilename: '',
        aggregateSize: 0,
        logSize: 0,
        logFilename: '',
        logFileSlug: null,
        logProgress: 0,
        aggregateProgress: 0,
        statuses: {
            inProgress: 'in_process',
            success: 'success',
            failed: 'failed',
        },
        timer: null,
    }),

    getters: {

    },

    actions: {
        changeShowPopup() {
            this.showPopup = !this.showPopup
        },

        async goToExportAggregatedData(params) {
            const defaultStore = useDefaultStore()
            const reportingStore = useReportingStore()
            this.downloadingAggregate = true
            this.downloadingAggregateStatus = this.statuses.inProgress
            this.aggregateFilename = params.type === exportType.CSV ? 'calls-aggregated-export.csv' : 'calls-aggregated-export.xlsx'

            const sendParams = {
                ...params,
                ...reportingStore.userSelected,
            }

            const res = await exportAggregatedData({ params: sendParams, onDownloadProgress: this.onDownloadAggregatedProgress })

            if (res?.data && res.data.success === false) {
                !!res.data.message && defaultStore.setErrorMessage({ message: res.data.message })
                this.downloadingAggregateStatus = this.statuses.failed
            } else if (res?.success === false) {
                !!res.message && defaultStore.setErrorMessage({ message: res.message })
                this.downloadingAggregateStatus = this.statuses.failed
            } else {
                this.downloadingAggregateStatus = this.statuses.success
                downloadFile(res, params.type)
            }

            this.downloadingAggregate = false
            this.aggregateSize = 0
        },

        onDownloadAggregatedProgress(progressEvent) {
            if (progressEvent.loaded) {
                this.aggregateSize = Math.round(progressEvent.loaded * 0.000001)
            }
        },

        async goToExportLogCSVData(params) {
            const defaultStore = useDefaultStore()
            const reportingStore = useReportingStore()
            this.downloadingLog = true
            this.downloadingLogStatus = this.statuses.inProgress
            this.logFilename = 'call-log-export.csv'
            this.logSize = 0

            const sendParams = {
                ...params,
                ...reportingStore.userSelected,
            }

            const res = await exportLogCSVData({ params: sendParams, onDownloadProgress: this.onDownloadLogProgress })

            if (res?.data && res.data.success === false) {
                !!res.data.message && defaultStore.setErrorMessage({ message: res.data.message })
                this.downloadingLogStatus = this.statuses.failed
            } else if (res?.success === false) {
                !!res.message && defaultStore.setErrorMessage({ message: res.message })
                this.downloadingLogStatus = this.statuses.failed
            } else {
                downloadFile(res, 'csv')
                this.downloadingLogStatus = this.statuses.success
            }

            this.downloadingLog = false
        },

        onDownloadLogProgress(progressEvent) {
            if (progressEvent.loaded) {
                this.logSize = progressEvent.loaded * 0.000001
            }
        },

        async goToGenerateLogXLSXData(params) {
            const defaultStore = useDefaultStore()
            const reportingStore = useReportingStore()
            this.downloadingLog = true
            this.downloadingLogStatus = this.statuses.inProgress
            this.logSize = 0

            const sendParams = {
                ...params,
                ...reportingStore.userSelected,
            }

            const { success, payload, message } = await generateLogXLSXData(sendParams)

            if (success) {
                this.logFilename = payload.name
                this.logFileSlug = payload.slug
                this.checkStatus(payload.status)
            } else if (message === 'Report generation is in progress') {
                this.goToCheckLogXLSXStatuses()
            } else {
                defaultStore.setErrorMessage({ message })
                this.downloadingLog = false
                this.downloadingLogStatus = this.statuses.failed
                this.logFilename = 'call-log-export.xlsx'
            }
        },

        checkStatus(status) {
            if (!status) {
                this.downloadingLog = false
                this.downloadingLogStatus = null
                this.resetTimer()

                return
            }

            switch (status) {
            case this.statuses.inProgress:
                this.downloadingLog = true
                this.downloadingLogStatus = this.statuses.inProgress
                this.setTimerForCheckLogXLSXStatus()
                break
            case this.statuses.success:
                this.downloadingLogStatus = this.statuses.success
                this.resetTimer()
                this.goToDownloadLogXLSX()
                break
            case this.statuses.failed:
                this.downloadingLog = false
                this.downloadingLogStatus = this.statuses.failed
                this.resetTimer()
                break
            }
        },

        setTimerForCheckLogXLSXStatus() {
            this.resetTimer()

            this.timer = setInterval(() => {
                this.downloadingLog = true
                this.goToCheckLogXLSXStatuses()
            }, 10000)
        },

        resetTimer() {
            clearInterval(this.timer)
        },

        async goToCheckLogXLSXStatuses() {
            const permissionsStore = usePermissionsStore()

            if (!permissionsStore.permissions.reporting.download) {
                return
            }

            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getLogXLSXStatuses()

            if (success) {
                this.logFilename = payload.name
                this.logFileSlug = payload.slug
                this.checkStatus(payload.hasOwnProperty('status') ? payload.status : null)
            } else {
                defaultStore.setErrorMessage({ message })
                this.resetTimer()
                this.downloadingLog = false
                this.downloadingLogStatus = this.statuses.failed
            }
        },

        async goToDownloadLogXLSX() {
            const defaultStore = useDefaultStore()
            this.downloadingLog = true

            const res = await downloadLogXLSXData(this.logFileSlug)

            if (res?.data && res.data.success === false) {
                !!res.data.message && defaultStore.setErrorMessage({ message: res.data.message })
                this.downloadingLogStatus = this.statuses.failed
            } else if (res?.success === false) {
                !!res.message && defaultStore.setErrorMessage({ message: res.message })
                this.downloadingLogStatus = this.statuses.failed
            } else {
                this.downloadingLogStatus = this.statuses.success
                downloadFile(res, 'xlsx')
            }

            this.downloadingLog = false
        },
    },
})
