import { defineStore } from 'pinia'

import {
    attachNumberPools,
    detachNumberPools,
    getAvailableNumberPools,
    updateAttachedNumberPool,
} from '@/api/call-tracking/campaign/campaign-actions-routes'
import { updateNumberPoolStatus } from '@/api/pool-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useDetailCampaignTrackingNumberTableStore } from '@/store/call-tracking/campaigns/detailCampaignTrackingNumberTable'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_STATUS } from '@/constants/localFilters'
import { NUMBERABLE_TYPES } from '@/constants/numberableTypes'

export const useDetailCampaignNumberPoolsStore = defineStore('detailCampaignNumberPoolsStore', {
    state: () => ({
        loading: false,
        actionLoading: false,

        selectedNumberPoolSlug: null,

        vendors: [],

        availableNumberPools: [],

        availableNumberPoolsFilters: [
            FILTER_STATUS,
        ],
    }),
    getters: {
        selectedNumberPool(state) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            return state.selectedNumberPoolSlug ? detailCampaignTrackingNumberTableStore.numberPools.find((n) => n.slug === state.selectedNumberPoolSlug) : null
        },

        attachedVendor(state) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()

            return (state.selectedNumberPoolSlug && this.selectedNumberPool?.vendor_id)
                ? detailCampaignTrackingNumberTableStore.attachedVendors.find((v) => this.selectedNumberPool.vendor_id === v.pivot.vendor_id && v.pivot.numberable_id === this.selectedNumberPool.id && vendor.pivot.numberable_type === NUMBERABLE_TYPES.DYNAMIC_NUMBER)
                : null
        },
    },
    actions: {
        setSelectedNumberPool(slug) {
            this.selectedNumberPoolSlug = slug
        },

        getQueryLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()
            const localFilterTableStore = useLocalFilterTableStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.campaignAvailableNumberPools, this.availableNumberPoolsFilters)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.campaignAvailableNumberPools, detailCampaignStore.id)
        },

        async goToLoadAvailableNumberPools(slug, withoutLoading = false) {
            const defaultStore = useDefaultStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getAvailableNumberPools(slug)

            if (success) {
                this.availableNumberPools = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToUpdateNumberPoolStatus({ slugs, status_id }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, payload, message } = await updateNumberPoolStatus({ slugs, status_id })

            if (success) {
                detailCampaignTrackingNumberTableStore.updateNumberPoolStatus(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToAttachNumberPoolsToCampaign({ numberPools, slug }) {
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, payload, message } = await attachNumberPools({
                formData: { number_pool_slugs: numberPools.map((n) => n.slug) },
                slug,
            })

            if (success) {
                this.setAttachedNumbersAndVendors(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        setAttachedNumbersAndVendors({ numbers = [], vendors = [], phone_number_pools = [] }) {
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()

            detailCampaignTrackingNumberTableStore.setVendors(vendors)

            const parseNumbers = detailCampaignTrackingNumberTableStore.parseNumbers({ numbers })
            const parseNumberPools = detailCampaignTrackingNumberTableStore.parseNumberPools({ phone_number_pools })

            detailCampaignTrackingNumberTableStore.setNumbers(parseNumbers)
            detailCampaignTrackingNumberTableStore.setNumberPools(parseNumberPools)
        },

        async goToDetachNumberPools({ slug, number_pool_slugs }) {
            const defaultStore = useDefaultStore()
            const detailCampaignTrackingNumberTableStore = useDetailCampaignTrackingNumberTableStore()

            this.actionLoading = true

            const { success, message } = await detachNumberPools({ slug, number_pool_slugs })

            if (success) {
                detailCampaignTrackingNumberTableStore._removeNumberPoolsAfterDelete(number_pool_slugs)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToUpdateNumberPool({ formData, slug }) {
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, payload, message } = await updateAttachedNumberPool({ formData, slug })

            if (success) {
                this.setAttachedNumbersAndVendors(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false
        },
    },
})
