import { defineStore } from 'pinia'

import { checkEqualsFilter, createFiltersForSend, localFilterItems } from '@/helpers/filters-sorting-header-table-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'

import { useSortFilterTableStore } from '@/store/sortFilterTableStore'

import { FILTER_RULES } from '@/constants/filterRules'
import { viewPorts } from '@/constants/viewPorts'
import { LOCAL_FILTER_SHORT_NAME } from '@/constants/localFilterShortName'

export const useLocalFilterTableStore = defineStore('localFilterTableStore', {
    state: () => ({
        tables: {},
    }),

    actions: {
        initializeTableSettings(headerType, availableFilters = []) {
            if (!this.tables[headerType]) {
                this.tables[headerType] = {
                    filters: availableFilters,
                    selectedFilters: [],
                    filtersForSend: {},

                    activeColumnsTable: [],
                    visibleHeadersTable: [],
                    headers: [],
                }
            } else {
                this.tables[headerType].filters = availableFilters
            }
        },

        getTable(headerType) {
            return this.tables[headerType] || {
                filters: [],
                selectedFilters: [],
                filtersForSend: {},
            }
        },

        controlParams(headerType) {
            const currentTable = this.getTable(headerType)

            return {
                filters: currentTable.filters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
            }
        },

        getFilteredItems(items, headerType) {
            const table = this.getTable(headerType)
            return localFilterItems(items, table.filtersForSend)
        },

        preparationFiltersForSend(headerType) {
            const table = this.getTable(headerType)

            const newFilters = createFiltersForSend(table.selectedFilters, true)

            if (!checkEqualsFilter(newFilters, table.filtersForSend)) {
                table.filtersForSend = newFilters
                return true
            }

            return false
        },

        setFilters(headerType, storageKey) {
            const filtersChanged = this.preparationFiltersForSend(headerType)

            if (filtersChanged) {
                this.saveFiltersInLocalStorage(headerType, storageKey)
            }
        },

        updateSelectedFilters(filters, headerType, storageKey) {
            const table = this.getTable(headerType)
            table.selectedFilters = filters
            this.setFilters(headerType, storageKey)
        },

        saveFiltersInLocalStorage(headerType, storageKey) {
            const table = this.getTable(headerType)
            const shortName = LOCAL_FILTER_SHORT_NAME[headerType] || headerType

            setFilterInLocalStorageByWorkspaceId(table.filtersForSend, `${shortName}.${storageKey}`)
        },

        getLocalStorageFilter(headerType, storageKey) {
            const shortName = LOCAL_FILTER_SHORT_NAME[headerType] || headerType
            const savedFilter = getFilterLocalStorageByWorkspaceId(`${shortName}.${storageKey}`)

            if (savedFilter && Object.keys(savedFilter).length) {
                const table = this.getTable(headerType)
                table.filtersForSend = savedFilter
            }
        },

        getQueryLocalStorage(headerType, storageKey) {
            this.getLocalStorageHeadersTable(headerType)

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter(headerType, storageKey)
                this.updateFiltersAfterQuery(headerType)
            }
        },

        updateFiltersAfterQuery(headerType) {
            const table = this.getTable(headerType)
            const savedFilter = table.filtersForSend
            if (!savedFilter || !Object.keys(savedFilter).length) return

            const updatedFilters = []

            const filterMap = new Map(table.filters.map((f) => [f.key, f]))

            Object.keys(savedFilter).forEach((key) => {
                const filterDefinition = filterMap.get(key)
                if (filterDefinition) {
                    updatedFilters.push({
                        ...filterDefinition,
                        value: savedFilter[key].value,
                        rule: FILTER_RULES[filterDefinition.type]?.find((rule) => rule.key === savedFilter[key].rule),
                    })
                }
            })

            table.selectedFilters = updatedFilters

            if (viewPorts.mob <= window.innerWidth) {
                this.openCloseFilter(headerType)
            }
        },

        resetFilters(headerType, storageKey) {
            const table = this.getTable(headerType)
            table.selectedFilters = []
            table.filtersForSend = {}
            this.saveFiltersInLocalStorage(headerType, storageKey)
        },

        openCloseFilter(headerType) {
            const sortFilterTableStore = useSortFilterTableStore()
            sortFilterTableStore.changeIndexOpen(headerType, -2)
            sortFilterTableStore.openCloseFilter(headerType)
        },

        setHeaders(headerType, headers) {
            this.tables[headerType].headers = headers
        },

        getLocalStorageHeadersTable(headerType) {
            this.tables[headerType].activeColumnsTable = getActiveColumnsHeader(this.tables[headerType].headers, headerType)
            this.setVisibleHeaders(headerType)
        },

        setVisibleHeaders(headerType) {
            this.tables[headerType].visibleHeadersTable = getActiveHeadersTable(this.tables[headerType].headers, this.tables[headerType].activeColumnsTable)
        },

        changeHeaders(payload, headerType) {
            this.tables[headerType].activeColumnsTable = payload
            this.setVisibleHeaders(headerType)
        },
    },
})
