import { useAuthorizationStore } from '@/store/authorizationStore'

function getFilter() {
    const authorizationStore = useAuthorizationStore()

    const filter = localStorage.getItem('filter')
    return filter ? JSON.parse(filter) : { [authorizationStore.getWorkspaceSlug]: {} }
}

function getSelectUser() {
    const authorizationStore = useAuthorizationStore()

    const selectUser = localStorage.getItem('selectUser')
    return selectUser ? JSON.parse(selectUser) : { [authorizationStore.getWorkspaceSlug]: {} }
}

function setSelectUser(selectUser) {
    localStorage.setItem('selectUser', selectUser)
}

function setFilter(filter) {
    localStorage.setItem('filter', filter)
}

function removeFilter() {
    localStorage.removeItem('filter')
}

function getFilterLocalStorageByWorkspaceId(type) {
    const authorizationStore = useAuthorizationStore()

    const filters = getFilter()[authorizationStore.getWorkspaceSlug]

    if (filters && filters[type]) {
        return filters[type]
    }

    return {}
}

function getSelectUserLocalStorageByWorkspaceId(type) {
    const authorizationStore = useAuthorizationStore()

    const selectUser = getSelectUser()[authorizationStore.getWorkspaceSlug]

    if (selectUser && selectUser[type]) {
        return selectUser[type]
    }

    return {}
}

function setSelectUserInLocalStorageByWorkspaceId(selectUser, type) {
    const authorizationStore = useAuthorizationStore()

    let activeSelectUser = getSelectUser()

    if (!activeSelectUser[authorizationStore.getWorkspaceSlug]) {
        activeSelectUser = {
            ...activeSelectUser,
            [authorizationStore.getWorkspaceSlug]: {
                [type]: selectUser,
            },
        }
    } else if ((!selectUser || !Object.keys(selectUser).length)) {
        delete activeSelectUser[authorizationStore.getWorkspaceSlug][type]
    } else {
        activeSelectUser[authorizationStore.getWorkspaceSlug][type] = selectUser
    }

    setSelectUser(JSON.stringify(activeSelectUser))
}

function setFilterInLocalStorageByWorkspaceId(filter, type) {
    const authorizationStore = useAuthorizationStore()

    let activeFilter = getFilter()

    if (!activeFilter[authorizationStore.getWorkspaceSlug]) {
        activeFilter = {
            ...activeFilter,
            [authorizationStore.getWorkspaceSlug]: {
                [type]: filter,
            },
        }
    } else if ((!filter || !Object.keys(filter).length)) {
        delete activeFilter[authorizationStore.getWorkspaceSlug][type]
    } else {
        activeFilter[authorizationStore.getWorkspaceSlug][type] = filter
    }

    setFilter(JSON.stringify(activeFilter))
}

export {
    getFilterLocalStorageByWorkspaceId,
    setFilterInLocalStorageByWorkspaceId,
    setSelectUserInLocalStorageByWorkspaceId,
    getSelectUserLocalStorageByWorkspaceId,
    removeFilter,
}
