import { defineStore } from 'pinia'
import {
    addFilterInColumn,
    checkEqualsFilter,
    createFiltersForSend,
    createSortForSend,
    getQuery,
    setFilter,
    setQuery,
    setSorting,
    updateHeaderAfterSort,
    updateSortHeaderColumn,
    updateSortingAfterReset,
    updateSortingControl,
} from '@/helpers/filters-sorting-header-table-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import { getSortingInLocalStorageByWorkspaceId, setSortingInLocalStorageByWorkspaceId } from '@/helpers/save-sorting-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'

import { VIEWS_TABLE_MOBILE_DIALOG } from '@/constants/viewsTableMobileDialog'
import { SORT_RULES } from '@/constants/sortRules'
import { FILTER_RULES } from '@/constants/filterRules'
import { viewPorts } from '@/constants/viewPorts'

export const useSortFilterTableStore = defineStore('sortFilterTable', {
    state: () => ({
        enableFilter: false,
        enableSorting: false,
        filtersTable: [],
        indexOpenFilter: {},
        showTableSortMenu: false,
        showDialog: false,
        currentView: VIEWS_TABLE_MOBILE_DIALOG.main,
        selectedItem: null,

        tables: {},
    }),

    actions: {
        initializeTableSettings(headerType) {
            if (!this.tables[headerType]) {
                this.tables[headerType] = {
                    filters: [],
                    sorting: [],
                    selectedFilters: [],
                    selectedSorting: [],
                    filtersForSend: {},
                    sortingForSend: {},
                    activeColumnsTable: [],
                    visibleHeadersTable: [],
                    headers: [],
                }
            }
        },

        clearTableSettings() {
            Object.keys(this.tables).forEach((headerType) => {
                this.tables[headerType].selectedFilters = []
                this.tables[headerType].selectedSorting = []
                this.tables[headerType].filtersForSend = {}
                this.tables[headerType].sortingForSend = {}
                this.tables[headerType].activeColumnsTable = []
                this.tables[headerType].visibleHeadersTable = []
                this.tables[headerType].headers = []
            })
        },

        getTable(headerType) {
            return this.tables[headerType] || {
                filters: [],
                sorting: [],
                selectedFilters: [],
                selectedSorting: [],
                filtersForSend: {},
                sortingForSend: {},
                activeColumnsTable: [],
                visibleHeadersTable: [],
                headers: [],
            }
        },

        controlParams(headerType) {
            const currentTable = this.getTable(headerType)

            return {
                filters: currentTable.filters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: currentTable.sorting,
            }
        },

        setHeaders(headerType, headers) {
            this.tables[headerType].headers = headers
        },

        checkFilter(type) {
            return this.filtersTable.includes(type)
        },

        removeFilter(type) {
            this.filtersTable = this.filtersTable.filter((el) => el !== type)
            this.indexOpenFilter[type] = undefined
        },

        removeFilters() {
            this.filtersTable = []
            this.indexOpenFilter = {}
        },

        openCloseFilter(type) {
            if (this.checkFilter(type)) {
                this.removeFilter(type)
            } else {
                this.filtersTable.push(type)
            }
        },

        openCloseSorting(value) {
            this.enableSorting = value
        },

        changeIndexOpen(headerType, index) {
            this.indexOpenFilter = { [headerType]: index }
        },

        openCloseTableSortMenu(value) {
            this.showTableSortMenu = value
        },

        changeCurrentView(view) {
            this.currentView = view
        },

        onSelectedItem(item) {
            this.selectedItem = item
        },

        setFilters(headerType, filters) {
            this.tables[headerType].filters = filters.filter((filter) => filter.type !== 'date')
            this.updateFilterAfterQueryLocalStorage(this.tables[headerType].filtersForSend, headerType)
            this.tables[headerType].visibleHeadersTable = addFilterInColumn(this.tables[headerType].visibleHeadersTable, this.tables[headerType].filters)
        },

        setSorting(headerType, sorting) {
            this.tables[headerType].sorting = sorting.filter((filter) => filter.key !== 'byDate')
            this.updateSortingAfterQueryLocalStorage(this.tables[headerType].sortingForSend, headerType)
        },

        updateFilterAfterQueryLocalStorage(savedFilter, headerType) {
            const filterMap = new Map(this.tables[headerType].filters.map((f) => [f.key, f]))

            this.tables[headerType].selectedFilters = Object.keys(savedFilter).reduce((acc, filterKey) => {
                const filter = filterMap.get(filterKey)
                if (!filter) {
                    return acc
                }

                acc.push({
                    ...filter,
                    value: savedFilter[filterKey].value,
                    rule: FILTER_RULES[filter.type]?.find((rule) => rule.key === savedFilter[filterKey].rule),
                })

                return acc
            }, [])

            if (this.tables[headerType].selectedFilters.length && viewPorts.mob <= window.innerWidth) {
                this.openCloseFilter(headerType)
            }
        },

        updateSortingAfterQueryLocalStorage(savedSort, headerType) {
            if (!Object.keys(savedSort).length) {
                return
            }

            Object.keys(savedSort).forEach((key) => {
                const findItem = this.tables[headerType].sorting.find((el) => el.key === key)

                if (findItem) {
                    this.createSelectedSorting(findItem, savedSort[key], headerType)
                    this.updateSortingForSend(findItem, true, headerType)
                    this.openCloseSorting(true)
                }
            })

            this.addSortingToHeader(headerType)
        },

        updateSelectedFilters(payload, headerType) {
            this.tables[headerType].selectedFilters = payload
            this.sendFiltersSorting(headerType)
        },

        sendFiltersSorting(headerType) {
            const filtersChanged = this.preparationFiltersForSend(headerType)
            const sortingChanged = this.preparationSortingForSend(headerType)

            if (filtersChanged || sortingChanged) {
                this.saveFiltersSortingInLocalStorage(headerType)
                this.saveQuery(headerType)
            }
        },

        preparationFiltersForSend(headerType) {
            const newFilter = createFiltersForSend(this.tables[headerType].selectedFilters)

            if (checkEqualsFilter(newFilter, this.tables[headerType].filtersForSend)) {
                return false
            }

            this.tables[headerType].filtersForSend = newFilter
            return true
        },

        preparationSortingForSend(headerType) {
            const newSorting = createSortForSend(this.tables[headerType].selectedSorting, this.tables[headerType].visibleHeadersTable)

            if (checkEqualsFilter(newSorting, this.tables[headerType].sortingForSend)) {
                return false
            }

            this.tables[headerType].sortingForSend = newSorting
            this.tables[headerType].visibleHeadersTable = updateHeaderAfterSort(this.tables[headerType].visibleHeadersTable, this.tables[headerType].sortingForSend)
            return true
        },

        saveFiltersSortingInLocalStorage(headerType) {
            setSortingInLocalStorageByWorkspaceId(this.tables[headerType].sortingForSend, headerType)
            setFilterInLocalStorageByWorkspaceId(this.tables[headerType].filtersForSend, headerType)
        },

        saveQuery(headerType) {
            const { sortingForSend, filtersForSend } = this.getTable(headerType)
            this.router.replace({ query: setQuery(sortingForSend, filtersForSend) }).catch(() => {})
        },

        removeAllSorting(headerType) {
            this.tables[headerType].selectedSorting = []
            this.tables[headerType].sorting = updateSortingAfterReset(this.tables[headerType].sorting)
        },

        resetSortFilter(headerType) {
            this.tables[headerType].selectedFilters = []
            this.removeAllSorting(headerType)
            this.tables[headerType].filtersForSend = null
            this.tables[headerType].sortingForSend = {}
            this.tables[headerType].visibleHeadersTable = updateHeaderAfterSort(this.tables[headerType].visibleHeadersTable)
            this.sendFiltersSorting(headerType)
        },

        changeSelectedSortingAfterDelete(item, headerType) {
            this.tables[headerType].selectedSorting = this.tables[headerType].selectedSorting.filter((el) => el.key !== item.key)
            this.updateSortingForSend(item, false, headerType)
        },

        changeSelectedSortingAfterAdd(item, headerType) {
            this.createSelectedSorting(item, '', headerType)
            this.updateSortingForSend(item, true, headerType)
        },

        createSelectedSorting(item, key, headerType) {
            const role = key ? SORT_RULES.find((r) => r.key === key) : SORT_RULES[0]
            this.tables[headerType].selectedSorting = [...this.tables[headerType].selectedSorting, { ...item, value: role }]
        },

        updateSortingForSend(item, value, headerType) {
            this.tables[headerType].sorting = updateSortingControl({ sortSettings: { sortBy: item.key } }, this.tables[headerType].sorting, value)
        },

        addFilterFromTable(filter, headerType) {
            const index = this.tables[headerType].selectedFilters.findIndex((item) => item.key === filter.filterSettings.key)

            const { items, filterOpen } = setFilter(filter, [...this.tables[headerType].selectedFilters])

            if (index === -1) {
                this.updateSelectedFilters(items, headerType)
            }

            this.changeIndexOpen(headerType, filterOpen)
            this.openCloseFilter(headerType)
        },

        addSortingFromTable(sort, headerType) {
            this.tables[headerType].selectedSorting = setSorting(sort, [...this.tables[headerType].selectedSorting])
            this.tables[headerType].visibleHeadersTable = updateSortHeaderColumn(sort, this.tables[headerType].visibleHeadersTable)
            this.tables[headerType].sorting = updateSortingControl(sort, this.tables[headerType].sorting, true)
            this.sendFiltersSorting(headerType)
            this.openCloseTableSortMenu(true)
            this.openCloseSorting(true)
        },

        getQueryLocalStorage(headerType) {
            this.getLocalStorageHeadersTable(headerType)

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getQueryFilter(headerType)
                this.getQuerySorting(headerType)
                this.getLocalStorageFilter(headerType)
                this.getLocalStorageSorting(headerType)
            }
        },

        getQueryFilter(headerType) {
            const { filter = {} } = getQuery()

            if (Object.keys(filter).length) {
                this.tables[headerType].filtersForSend = filter
                setFilterInLocalStorageByWorkspaceId(this.tables[headerType].filtersForSend, headerType)
            }
        },

        getQuerySorting(headerType) {
            const { sorting = {} } = getQuery()

            if (Object.keys(sorting).length) {
                this.tables[headerType].sortingForSend = sorting
                setSortingInLocalStorageByWorkspaceId(this.tables[headerType].sortingForSend, headerType)
            }
        },

        getLocalStorageFilter(headerType) {
            const savedFilter = getFilterLocalStorageByWorkspaceId(headerType)

            if (savedFilter && Object.keys(savedFilter).length) {
                this.tables[headerType].filtersForSend = savedFilter
                this.saveQuery(headerType)
            }
        },

        getLocalStorageSorting(headerType) {
            const savedSort = getSortingInLocalStorageByWorkspaceId(headerType)

            if (savedSort && Object.keys(savedSort).length) {
                this.tables[headerType].sortingForSend = savedSort
                this.saveQuery(headerType)
            }
        },

        getLocalStorageHeadersTable(headerType) {
            this.tables[headerType].activeColumnsTable = getActiveColumnsHeader(this.tables[headerType].headers, headerType)
            this.setVisibleHeaders(headerType)
        },

        setVisibleHeaders(headerType) {
            this.tables[headerType].visibleHeadersTable = getActiveHeadersTable(this.tables[headerType].headers, this.tables[headerType].activeColumnsTable)
        },

        addSortingToHeader(headerType) {
            this.tables[headerType].selectedSorting.forEach((sort) => {
                const obj = {
                    sortSettings: {
                        sortBy: sort.key,
                    },
                    ...sort,
                }

                this.tables[headerType].visibleHeadersTable = updateSortHeaderColumn(obj, this.tables[headerType].visibleHeadersTable)
            })
        },

        changeHeaders(payload, headerType) {
            this.tables[headerType].activeColumnsTable = payload
            this.setVisibleHeaders(headerType)
            this.tables[headerType].visibleHeadersTable = addFilterInColumn(this.tables[headerType].visibleHeadersTable, this.tables[headerType].filters)
        },
    },
})
