import axios from 'axios'

import { getAccessToken, removeAccessToken } from '@/helpers/auth-helper'

import { useAuthorizationStore } from '@/store/authorizationStore'

const axiosApi = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}/api/`,
})

axiosApi.interceptors.request.use(
    (config) => {
        const token = getAccessToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },

    (error) => Promise.reject(error),
)

axiosApi.interceptors.response.use(
    (response) => {
        const parser = new DOMParser()

        if (response.headers['content-type'].indexOf('text/plain;') + 1) {
            return Promise.resolve(response)
        }

        if (response.headers['content-type'].indexOf('application/xml') + 1) {
            return Promise.resolve({
                payload: {
                    data: parser.parseFromString(response.data, 'application/xml'),
                    xml: true,
                },
                status: response?.status || response?.headers?.status,
                message: '',
                success: true,
            })
        }

        return Promise.resolve({
            ...response.data,
            status: response?.status || response?.headers?.status,
        })
    },
    (error) => {
        if (error?.response?.status && !error.config.headers['Disabled-Check-Error']) {
            const authorizationStore = useAuthorizationStore()

            switch (error.response.status) {
            // case 303:
            //   store.commit(authTypes.mutations.SET_ACCOUNT_PBX, false)
            //   break
            case 401:
                removeAccessToken()
                authorizationStore.$reset()
                break
            // case 402:
            //     authorizationStore.changePaymentRedirect(true)
            //     break
                // case 403:
                //   store.commit(authTypes.mutations.REDIRECT_FORBIDDEN, true)
                //   break
                // case 409:
                //   store.commit(authTypes.mutations.REDIRECT_ACCOUNT_BLOCKED, true)
                //   break
            }
        }

        const message = error?.response ? error.response.data.message : error.message
        return Promise.resolve({
            success: false,
            status: error?.response?.status || error?.code,
            message,
            payload: error?.response?.data,
        })
    },
)

export default axiosApi
