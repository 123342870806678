import {
    localFilterBoolean, localFilterList, localFilterNumber, localFilterString,
} from '@/helpers/filters-local-helper'

import { FILTER_RULES } from '@/constants/filterRules'
import { FILTERS_TABLE } from '@/constants/filtersTable'

function createRangeForSend(range = [], preset = {}) {
    const { key } = preset

    return {
        range,
        preset: key,
    }
}

function createFiltersForSend(filters, withType, withCustom) {
    return filters.reduce((allFilters, filter) => {
        const {
            type, key, value, rule = {}, custom,
        } = filter

        if (type === FILTERS_TABLE.date && value) {
            const [firstDate, lastDate] = value
            allFilters[key] = firstDate
            allFilters[`${key}To`] = lastDate
        } else if (type === FILTERS_TABLE.select) {
            allFilters[key] = createSelectValue(key, value, rule, withType && type, withCustom && custom)
        } else if (type === FILTERS_TABLE.select_with_empty) {
            allFilters[key] = createSelectValue(key, value, rule, withType && type, withCustom && custom)
        } else if (type === FILTERS_TABLE.caller_profile) {
            allFilters[key] = value || undefined
        } else if (type === FILTERS_TABLE.custom_parameters) {
            allFilters[key] = value || undefined
        } else if (type === FILTERS_TABLE.session_data) {
            allFilters[key] = value || undefined
        } else if (type === FILTERS_TABLE.parameters) {
            if (!value) {
                return allFilters
            }

            allFilters[key] = createParametersValue(value)
        } else if (rule) {
            allFilters[key] = {
                value,
                rule: rule.key,
            }

            if (withCustom) {
                allFilters[key].custom = custom
            }

            if (withType) {
                allFilters[key].type = type
            }
        }

        return allFilters
    }, {})
}

function createSelectValue(key, value, rule, type, custom) {
    const allFilters = {
        value: value ? value.id : undefined,
        rule: rule.key,
    }

    if (checkName(key) && value) {
        allFilters.name = value.name
    }

    if (type) {
        allFilters.type = type
    }

    if (custom) {
        allFilters.custom = custom
    }

    return allFilters
}

function checkEqualsFilter(newFilter, oldFilter) {
    return JSON.stringify(newFilter) === JSON.stringify(oldFilter)
}

function createParametersValue(parameters) {
    const parametersFilter = {}

    parameters.forEach((el, index) => {
        const { filter = {} } = el
        const { key, value } = filter
        parametersFilter[`${key}][${[index]}`] = value
    })

    return parametersFilter
}

function setFilter(filter, itemsFilter) {
    const { filterSettings = {} } = filter
    const { type, key } = filterSettings

    const item = {
        ...filterSettings,
        rule: FILTER_RULES[type][0],
    }

    const findIndex = itemsFilter.findIndex((f) => f.key === key)

    if (findIndex !== -1) {
        itemsFilter[findIndex] = item

        return {
            items: itemsFilter,
            filterOpen: findIndex,
        }
    }

    return {
        items: [...itemsFilter, item],
        filterOpen: itemsFilter.length,
    }
}

function addFilterInColumn(headers, filters) {
    return headers.map((column) => {
        const { filterSettings, value } = column

        const findColumnSettings = filters.find((f) => f.key === value)

        if (filterSettings && findColumnSettings) {
            return { ...column, filterSettings: findColumnSettings }
        }

        return column
    })
}

function createSortForSend(sorts, headers) {
    return sorts.reduce((allSorts, sort) => {
        const { key, value } = sort
        allSorts[key] = value.key

        _updateHeaderColumn(headers, key, value.key)
        return allSorts
    }, {})
}

function setSorting(sort, itemsSort) {
    const { sortSettings = {}, value = {}, name } = sort
    const { sortBy } = sortSettings

    const item = {
        label: name,
        key: sortBy,
        value,
    }

    const findIndex = itemsSort.findIndex((s) => s.key === sortBy)

    if (findIndex !== -1) {
        itemsSort[findIndex] = item
    } else {
        itemsSort.push(item)
    }

    return itemsSort
}

function _updateHeaderColumn(headers, sortBy, key) {
    const findHeader = headers.find((h) => h.sortSettings && h.sortSettings.sortBy === sortBy)

    if (findHeader) {
        findHeader.sortSettings.key = key
    }
}

function updateSortHeaderColumn({ sortSettings = {}, value = {} }, headers) {
    const findHeader = headers.find((h) => h.sortSettings && h.sortSettings.sortBy === sortSettings.sortBy)

    if (findHeader) {
        findHeader.sortSettings.key = value.key
    }

    return headers
}

function updateSortingControl({ sortSettings = {} }, sorting, value) {
    const findItem = sorting.find((s) => s.key === sortSettings.sortBy)
    findItem.hideSelect = value

    return sorting
}

function updateHeaderAfterSort(headers = [], resetField = {}) {
    return headers.map((el) => {
        const { sortSettings = {} } = el
        if (sortSettings.sortBy && !resetField[sortSettings.sortBy]) {
            sortSettings.key = ''
            return el
        }

        return el
    })
}

function updateSortingAfterReset(sorting) {
    return sorting.map((el) => {
        const { hideSelect, ...other } = el
        return other
    })
}

function getQuery() {
    const { search } = window.location
    const sorting = {}
    const filter = {}
    const otherFilter = {}

    const userSelected = {}
    const range = []
    let preset = null

    const params = new URLSearchParams(search)
    let paramCounts = -1 // Счётчик для отслеживания индексов параметров

    params.forEach((value, key) => {
        const decodedValue = decodeURIComponent(value)

        if (key.includes('by')) {
            sorting[key] = value
        } else if (key === 'asSlug' || key === 'asType') {
            userSelected[key] = value || null
        } else if (key.endsWith('Value') && value) {
            const name = key.slice(0, -5)

            filter[name] = {
                ...filter[name],
                value: decodedValue.includes('[') ? JSON.parse(value) : decodedValue,
            }
        } else if (key.endsWith('Name') && value) {
            const name = key.slice(0, -4)

            filter[name] = {
                ...filter[name],
                name: decodedValue,
            }
        } else if (key.endsWith('Key') && value) {
            const name = key.slice(0, -3)

            filter[name] = {
                ...filter[name],
                key: decodedValue,
            }
        } else if (key.endsWith('Rule')) {
            const name = key.slice(0, -4)

            filter[name] = {
                ...filter[name],
                rule: value,
            }
        } else if (key.endsWith('Type')) {
            const name = key.slice(0, -4)

            filter[name] = {
                ...filter[name],
                type: value,
            }
        } else if (key.startsWith('parameters')) {
            const paramName = decodeURIComponent(key).slice(10)
            const decodedParamValue = decodeURIComponent(value)

            paramCounts += 1

            filter.parameters = {
                ...filter.parameters,
                [`${paramName}][${paramCounts}`]: decodedParamValue,
            }
        } else if (key.includes('range')) {
            range.push(value)
        } else if (key === 'preset') {
            preset = value
        } else if (key.endsWith('Other')) {
            const name = key.slice(0, -5)

            otherFilter[name] = decodedValue
        }
    })

    return {
        sorting,
        filter,
        range,
        otherFilter,
        preset,
        userSelected,
    }
}

function checkName(key) {
    const names = ['numbers', 'trafficSources', 'types', 'replacementNumber', 'callerProfile', 'phoneNumberPool', 'customParameters', 'sessionData']
    return names.includes(key)
}

function processFilterKey(queryFilter, key, filter, isLocal) {
    if (key === 'parameters') {
        Object.entries(filter).forEach(([paramName, paramValue]) => {
            const name = encodeURIComponent(paramName.slice(0, -3))
            queryFilter[`${key}${name}`] = encodeURIComponent(paramValue)
        })
    } else if (Array.isArray(filter.value)) {
        queryFilter[`${key}Value`] = JSON.stringify(filter.value)
    } else if (filter.value) {
        queryFilter[`${key}Value`] = encodeURIComponent(filter.value)

        if (filter.name) {
            queryFilter[`${key}Name`] = encodeURIComponent(filter.name)
        }

        if (key === 'callerProfile') {
            queryFilter[`${key}Key`] = encodeURIComponent(filter.key)
        }

        if (key === 'sessionData') {
            queryFilter[`${key}Key`] = encodeURIComponent(filter.key)
        }
    }

    if (isLocal && filter.type) {
        queryFilter[`${key}Type`] = filter.type
    }

    if (filter.rule) {
        queryFilter[`${key}Rule`] = filter.rule
    }
}

function setQuery(sortingForSend, filtersForSend, otherFiltersForSend, isLocal = false) {
    const queryFilter = {}
    const queryOtherFilter = {}

    if (filtersForSend) {
        Object.keys(filtersForSend).forEach((key) => {
            processFilterKey(queryFilter, key, filtersForSend[key], isLocal)
        })
    }

    if (otherFiltersForSend) {
        Object.entries(otherFiltersForSend).forEach(([key, value]) => {
            queryOtherFilter[`${key}Other`] = encodeURIComponent(value)
        })
    }

    return {
        ...sortingForSend,
        ...queryFilter,
        ...queryOtherFilter,
    }
}

function checkValidDateRange(range) {
    if (!range.length) {
        return false
    }

    const [start, end] = range
    return isNaN(Date.parse(start)) || isNaN(Date.parse(end))
}

function localFilterItems(items, filters) {
    let localItems = [...items]

    Object.keys(filters).forEach((key) => {
        if (!filters[key].value && (filters[key].rule !== 'is_empty' && filters[key].rule !== 'is_not_empty')) {
            return
        }

        if (Array.isArray(filters[key].value) && !filters[key].value.length) {
            return
        }

        switch (filters[key].type) {
        case FILTERS_TABLE.list:
            localItems = localFilterList(localItems, filters[key].rule, filters[key].value, key)
            return

        case FILTERS_TABLE.string:
            localItems = localFilterString(localItems, filters[key].rule, filters[key].value, key)
            return

        case FILTERS_TABLE.number:
            localItems = localFilterNumber(localItems, filters[key].rule, filters[key].value, key)
            return

        case FILTERS_TABLE.boolean:
            localItems = localFilterBoolean(localItems, filters[key].rule, filters[key].value, key)
        }
    })

    return localItems
}

export {
    createFiltersForSend,
    createSortForSend,
    setFilter,
    checkEqualsFilter,
    addFilterInColumn,
    updateHeaderAfterSort,
    setSorting,
    updateSortHeaderColumn,
    updateSortingControl,
    updateSortingAfterReset,
    getQuery,
    setQuery,
    createRangeForSend,
    checkValidDateRange,
    localFilterItems,
}
