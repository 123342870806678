import { defineStore } from 'pinia'

import {
    getCallsFilters,
    getCallersKeysFilter,
    getSessionDataKeysFilter,
    getCallsFilterValues,
    attachTags,
    detachTags,
    getBuyersAndVendors,
} from '@/api/reporting-routes'

import {
    getActiveColumnsHeader,
    getActiveHeadersTable,
    setActiveColumnsHeader,
} from '@/helpers/settings-header-table-helper'
import {
    checkEqualsFilter,
    createFiltersForSend,
    getQuery,
    setQuery,
    updateSortHeaderColumn,
    createRangeForSend,
    checkValidDateRange,
} from '@/helpers/filters-sorting-header-table-helper'
import { separatingWordsCharacter, capitalizeFirstLetter } from '@/helpers/app-helper'
import {
    getFilterLocalStorageByWorkspaceId, getSelectUserLocalStorageByWorkspaceId,
    setFilterInLocalStorageByWorkspaceId,
    setSelectUserInLocalStorageByWorkspaceId,
} from '@/helpers/save-filter-local-storage-helper'
import { getFilterReportingLocalStorageByWorkspaceId, setFilterReportingInLocalStorageByWorkspaceId } from '@/helpers/save-reporting-filter-local-storage-helper'
import { getRangeLocalStorageByWorkspaceId, setRangeInLocalStorageByWorkspaceId } from '@/helpers/save-range-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getCurrentTimezone, setTimezoneInLocalStorage } from '@/helpers/workspace-timezone-helper'
import { formatDateByTzOffset, getPresets } from '@/helpers/date-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { usePermissionsStore } from '@/store/permissionsStore'

import { BUYER_HEADERS, MANAGER_HEADERS, VENDOR_HEADERS } from '@/constants/headersTable/callLogHeadersTable'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_RULES } from '@/constants/filterRules'
import { FILTERS_REPORTING } from '@/constants/filtersReporting'
import { viewPorts } from '@/constants/viewPorts'

export const useReportingStore = defineStore('reporting', {
    state: () => ({
        loading: true,
        loadingFilterValues: false,
        loadingCallersKeys: false,
        loadingSessionDataKeys: false,

        buyers: [],
        vendors: [],

        checkDateBeforeRefresh: false,
        activeColumnsTable: [],
        visibleHeadersTable: [],

        filterValues: [],
        callersKeys: [],
        sessionDataKeys: [],
        filters: [],
        selectedFilters: [],
        selectedOtherFilters: [],
        filtersForSend: {},
        otherFiltersForSend: {},

        userSelected: {
            asType: null,
            asSlug: null,
        },

        currentTimezone: getCurrentTimezone(SETTINGS_HEADER_TABLE.reporting) || useAuthorizationStore().getWorkspaceTimezoneId,
        range: [],
        rangeForSend: {},
        currentPreset: {},
    }),

    getters: {
        controlParams(state) {
            return {
                filters: state.filters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                    values: [],
                })),
            }
        },

        allFiltersForSend(state) {
            return { ...state.filtersForSend, ...state.otherFiltersForSend }
        },

        timezoneOffset({ currentTimezone }) {
            const appStore = useAppStore()
            return appStore.getTimezoneOffsetById(currentTimezone)
        },

        presets() {
            return getPresets(this.timezoneOffset)
        },

        reportingBody({ range, currentTimezone }) {
            return {
                date: range[0] || formatDateByTzOffset(this.timezoneOffset),
                dateTo: range[1] || formatDateByTzOffset(this.timezoneOffset),
                timezone: currentTimezone,
            }
        },

        headerByRole(state) {
            const authorizationStore = useAuthorizationStore()

            if (authorizationStore.isBuyer || state.userSelected.asType === 'buyer') {
                return BUYER_HEADERS
            }

            if (authorizationStore.isVendor || state.userSelected.asType === 'vendor') {
                return this.checkRecordPermission(VENDOR_HEADERS)
            }

            return this.checkRecordPermission(MANAGER_HEADERS)
        },

        itemsBuyersAndVendors(state) {
            const buyers = {
                id: 1,
                name: 'buyer',
                label: 'Buyers',
                items: state.buyers,
                isOpen: false,
            }

            const vendors = {
                id: 2,
                name: 'vendor',
                label: 'Vendors',
                items: state.vendors,
                isOpen: false,
            }

            return [buyers, vendors]
        },
    },

    actions: {
        clearFilters() {
            this.filters = []
        },

        clearCallersKeys() {
            this.callersKeys = []
        },

        setSelectUser({ asType, asSlug }) {
            this.userSelected = {
                asSlug,
                asType,
            }

            setSelectUserInLocalStorageByWorkspaceId(this.userSelected, SETTINGS_HEADER_TABLE.reporting)

            this.saveQuery()
            this.setVisibleHeaders()
        },

        checkRecordPermission(headers) {
            const permissionsStore = usePermissionsStore()

            return permissionsStore.permissions.reporting.listen ? headers : headers.filter((h) => h.value !== 'record')
        },

        async goToAttachTags(slugs, uuid) {
            const defaultStore = useDefaultStore()

            const { success, message } = await attachTags({
                formData: { slugs },
                uuid,
            })

            if (success) {
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToDetachTags(slugs, uuid) {
            const defaultStore = useDefaultStore()

            const { success, message } = await detachTags({
                data: { slugs },
                uuid,
            })

            if (success) {
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToLoadBuyersAndVendors() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getBuyersAndVendors()

            if (success) {
                this.buyers = payload.buyers
                this.vendors = payload.vendors
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToLoadCallLogFilters() {
            if (this.filters.length) {
                return
            }

            const { success, payload, message } = await getCallsFilters(this.userSelected)

            if (success) {
                this.filters = payload

                this.updateFilterAfterQueryLocalStorage(this.filtersForSend)
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToLoadCallsFilterValues({ params, filter }) {
            this.loadingFilterValues = true

            const { success, payload, message } = await getCallsFilterValues({ params: { ...params, ...this.userSelected }, filter })

            if (success) {
                this.filterValues = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }

            this.loadingFilterValues = false

            return { success }
        },

        async goToLoadCallersKeysFilter() {
            if (this.callersKeys.length) {
                return
            }

            this.loadingCallersKeys = true

            const { success, payload, message } = await getCallersKeysFilter(this.userSelected)

            if (success) {
                this.callersKeys = this.parseCallersKeys(payload)
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }

            this.loadingCallersKeys = false
        },

        async goToLoadSessionDataKeysFilter() {
            if (this.sessionDataKeys.length) {
                return
            }

            this.loadingSessionDataKeys = true

            const { success, payload, message } = await getSessionDataKeysFilter()

            if (success) {
                this.sessionDataKeys = this.parseCallersKeys(payload)
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }

            this.loadingSessionDataKeys = false
        },

        parseCallersKeys(payload) {
            return payload.map((key) => ({
                name: capitalizeFirstLetter(separatingWordsCharacter(key)),
                id: key,
            }))
        },

        changeLoading(newLoading) {
            this.loading = newLoading
        },

        changeTimezone(id) {
            this.currentTimezone = id
            setTimezoneInLocalStorage(id, SETTINGS_HEADER_TABLE.reporting)
        },

        updateRange(newRange) {
            this.range = newRange

            this.checkCurrentPreset(newRange)
            this.preparationRangeForSend()
            this.saveRangeInLocalStorage()
            this.saveQuery()
        },

        checkCurrentPreset(range) {
            this.currentPreset = this.presets.find((preset) => {
                if (!preset.hasOwnProperty('range')) {
                    return true
                }

                return preset.range[0] === range[0] && preset.range[1] === range[1]
            })
        },

        updateFilterValues(filterValues) {
            this.filterValues = filterValues
        },

        updateCheckRefresh(newValue) {
            this.checkDateBeforeRefresh = newValue
        },

        refresh() {
            this.checkDateBeforeRefresh = true
        },

        setVisibleHeaders() {
            this.visibleHeadersTable = getActiveHeadersTable(this.headerByRole, this.activeColumnsTable)
        },

        addSortingToHeader(item) {
            const findHead = this.visibleHeadersTable.find((h) => h.sortSettings && h.sortSettings.key)

            if (findHead) {
                findHead.sortSettings.key = ''
            }

            this.visibleHeadersTable = updateSortHeaderColumn(item, this.visibleHeadersTable)
        },

        changeHeaders(payload) {
            this.activeColumnsTable = payload
            this.setVisibleHeaders()
        },

        getQueryLocalStorage() {
            this.getLocalStorageSelectUser()

            this.changeTimezone(getCurrentTimezone(SETTINGS_HEADER_TABLE.reporting) || useAuthorizationStore().getWorkspaceTimezoneId)
            this.getLocalStorageHeadersTable()

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getQueryFilter()
                this.getQueryUserSelected()
                this.getLocalStorageFilter()
                this.getLocalStorageOtherFilter()
                this.getQueryOtherFilter()
                this.getQueryRange()
                this.getLocalStorageRange()
                this.goToSetRange()
                this.updateOtherFilterAfterQueryLocalStorage()

                this.getCallsFilterValuesForTag()

                if (Object.keys(this.filtersForSend).length || Object.keys(this.otherFiltersForSend).length) {
                    this.openCloseFilter()
                }
            }

            if (!this.userSelected.asType && !this.userSelected.asSlug) {
                setActiveColumnsHeader(this.activeColumnsTable, SETTINGS_HEADER_TABLE.callLog)
            }

            setTimeout(() => {
                const appStore = useAppStore()
                appStore.setRouteLoading(false)
            }, 0)
        },

        goToSetRange() {
            if (!Object.keys(this.rangeForSend).length) {
                return
            }

            const { range, preset } = this.rangeForSend

            const findPreset = this.presets.find((p) => p.key === preset)

            if (findPreset && preset !== 'customRange') {
                this.range = findPreset.range
                this.rangeForSend.range = findPreset.range
                this.checkCurrentPreset(this.range)
                this.saveFiltersInLocalStorage()
                this.saveQuery()
            } else {
                this.range = range
            }
        },

        getQueryRange() {
            const { range = [], preset } = getQuery()

            if (checkValidDateRange(range)) {
                this.rangeForSend = {
                    range: [formatDateByTzOffset(this.timezoneOffset), formatDateByTzOffset(this.timezoneOffset)],
                }

                setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.callLog)
                return
            }

            if (range.length) {
                this.rangeForSend = { range, preset }
                setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.callLog)
            }
        },

        getQueryFilter() {
            const { filter = {} } = getQuery()

            if (Object.keys(filter).length) {
                this.filtersForSend = filter
                setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.reporting)
            }
        },

        getQueryUserSelected() {
            const { userSelected = {} } = getQuery()

            if (Object.keys(userSelected).length > 1) {
                this.userSelected = userSelected
                setSelectUserInLocalStorageByWorkspaceId(this.userSelected, SETTINGS_HEADER_TABLE.reporting)
            }
        },

        getQueryOtherFilter() {
            const { otherFilter = {} } = getQuery()

            if (Object.keys(otherFilter).length) {
                this.otherFiltersForSend = otherFilter
                setFilterReportingInLocalStorageByWorkspaceId(this.otherFiltersForSend, SETTINGS_HEADER_TABLE.callLog)
            }
        },

        getLocalStorageFilter() {
            const savedFilter = getFilterLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.reporting)

            if (savedFilter && Object.keys(savedFilter).length) {
                this.filtersForSend = savedFilter

                this.saveQuery()
            }
        },

        getLocalStorageSelectUser() {
            const savedFilter = getSelectUserLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.reporting)

            if (savedFilter && Object.keys(savedFilter).length) {
                const findElement = [...this.buyers, ...this.vendors].find((item) => item && item.slug === savedFilter.asSlug)

                this.userSelected = findElement ? savedFilter : { asType: null, asSlug: null }
            }
        },

        getLocalStorageOtherFilter() {
            const savedFilter = getFilterReportingLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.callLog)

            if (savedFilter && Object.keys(savedFilter).length) {
                this.otherFiltersForSend = savedFilter
                this.saveQuery()
            }
        },

        getLocalStorageRange() {
            const savedRange = getRangeLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.callLog)

            if (savedRange && Object.keys(savedRange).length) {
                if (checkValidDateRange(savedRange.range)) {
                    return
                }

                this.rangeForSend = savedRange
                this.saveQuery()
            }
        },

        getLocalStorageHeadersTable() {
            this.activeColumnsTable = getActiveColumnsHeader(this.headerByRole, SETTINGS_HEADER_TABLE.callLog)
            this.setVisibleHeaders()
        },

        getCallsFilterValuesForTag() {
            const { tags = {} } = this.filtersForSend
            const { value = [] } = tags

            if (value.length) {
                this.goToLoadCallsFilterValues({
                    params: this.reportingBody,
                    filter: FILTERS_REPORTING.tags.key,
                })
            }
        },

        saveFiltersInLocalStorage() {
            setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.reporting)
            setFilterReportingInLocalStorageByWorkspaceId(this.otherFiltersForSend, SETTINGS_HEADER_TABLE.callLog)
            setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.callLog)
        },

        saveRangeInLocalStorage() {
            setRangeInLocalStorageByWorkspaceId(this.rangeForSend, SETTINGS_HEADER_TABLE.callLog)
        },

        saveQuery() {
            const authorizationStore = useAuthorizationStore()

            const query = setQuery({}, this.filtersForSend, this.otherFiltersForSend)

            const saveQuery = { ...query, ...this.rangeForSend }

            if (this.userSelected.asType && this.userSelected.asSlug) {
                saveQuery.asType = this.userSelected.asType
                saveQuery.asSlug = this.userSelected.asSlug
            }

            this.router.replace({ params: { workspace: authorizationStore.getWorkspaceSlug }, query: saveQuery }).catch(() => {})
        },

        openCloseFilter() {
            const sortFilterTableStore = useSortFilterTableStore()

            if (viewPorts.mb >= window.innerWidth) {
                return
            }

            sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.reporting, -2)
            sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.reporting)
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.filters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                switch (key) {
                case FILTERS_REPORTING.parameters.key:
                    filters.push(this.createFilterForParameter(savedFilter, findItem, key))
                    break
                case FILTERS_REPORTING.duration.key:
                case FILTERS_REPORTING.uuid.key:
                    filters.push(this.createFilterNumberString(savedFilter, findItem, key))
                    break
                case FILTERS_REPORTING.callerProfile.key:
                case FILTERS_REPORTING.customParameters.key:
                case FILTERS_REPORTING.sessionData.key:
                    filters.push(this.createSingleLevelFilter(savedFilter, findItem, key))
                    break
                case FILTERS_REPORTING.tags.key:
                    filters.push(this.createFilterTags(savedFilter, findItem, key))
                    break
                default:
                    filters.push(this.createFilterDefault(savedFilter, findItem, key))
                }
            })

            if (filters.length) {
                this.selectedFilters = filters
            }
        },

        createFilterForParameter(savedFilter, findItem, key) {
            const value = []

            Object.keys(savedFilter[key]).forEach((name) => {
                const newItem = {
                    filter: {
                        key: [`${name.slice(0, -3)}`],
                        value: savedFilter[key][name],
                    },
                    name: savedFilter[key][name],
                }

                value.push(newItem)
            })

            return {
                ...findItem,
                value,
            }
        },

        createSingleLevelFilter(savedFilter, findItem, key) {
            if (savedFilter[key].value) {
                return {
                    ...findItem,
                    value: savedFilter[key],
                }
            }
        },

        createFilterTags(savedFilter, findItem, key) {
            return {
                ...findItem,
                value: savedFilter[key].value,
                rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
            }
        },

        createFilterDefault(savedFilter, findItem, key) {
            const newItem = {
                ...findItem,
                rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
            }

            if (savedFilter[key].value) {
                const { value, name } = savedFilter[key]

                newItem.value = {
                    id: value,
                    name: name || value,
                }
            }

            return newItem
        },

        createFilterNumberString(savedFilter, findItem, key) {
            const newItem = {
                ...findItem,
                rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
            }

            if (savedFilter[key].value) {
                const { value } = savedFilter[key]

                newItem.value = value
            }

            return newItem
        },

        updateOtherFilterAfterQueryLocalStorage() {
            const filters = []

            Object.keys(this.otherFiltersForSend).forEach((key) => {
                const newItem = {
                    name: key,
                    value: this.otherFiltersForSend[key],
                }

                filters.push(newItem)
            })

            if (filters.length) {
                this.selectedOtherFilters = filters
            }
        },

        updateOtherFilter(columnFilter) {
            if (columnFilter) {
                this.selectedOtherFilters = columnFilter
            }
        },

        updateFilter(mainFilter) {
            const findItem = this.filters.find((el) => el.key === mainFilter.key)

            if (findItem) {
                this.selectedFilters.push({ ...findItem, ...mainFilter })
            }
        },

        updateFilterParameters(key, value) {
            const findItem = this.selectedFilters.find((el) => el.key === key)

            if (!findItem) {
                return
            }

            const [currentItem] = value

            const isSelected = findItem.value.some((el) => el.name === currentItem.name)

            if (isSelected) {
                return
            }

            findItem.value = [...findItem.value, ...value]
        },

        addOtherFilterFromTable({ columnFilter, mainFilter = {} }) {
            const sortFilterTableStore = useSortFilterTableStore()

            const { key, value = {} } = mainFilter

            this.updateOtherFilter(columnFilter)

            const isSelected = this.selectedFilters.some((el) => el.key === key)

            if (FILTERS_REPORTING.parameters.key === key) {
                if (isSelected) {
                    this.updateFilterParameters(key, value)
                }

                const [currentItem = {}] = value
                const { filter = {} } = currentItem

                if (!isSelected && filter.value !== 'Totals') {
                    this.updateFilter(mainFilter)
                }
            } else if (!isSelected && value.id !== 'Totals') {
                this.updateFilter(mainFilter)
            }

            this.sendFilters()

            if (!sortFilterTableStore.checkFilter(SETTINGS_HEADER_TABLE.reporting)) {
                this.openCloseFilter()
            }
        },

        preparationRangeForSend() {
            this.rangeForSend = createRangeForSend(this.range, this.currentPreset)
        },

        preparationFiltersForSend() {
            const newFilter = createFiltersForSend(this.selectedFilters)

            if (!checkEqualsFilter(newFilter, this.filtersForSend)) {
                this.filtersForSend = newFilter
            }
        },

        preparationOtherFiltersForSend() {
            this.otherFiltersForSend = {}

            this.selectedOtherFilters.forEach((f) => {
                this.otherFiltersForSend[f.name] = f.value
            })
        },

        updateSelectedFiltersAfterRemoveTag(slug) {
            const findTags = this.selectedFilters.find((item) => item.key === FILTERS_REPORTING.tags.key)

            if (findTags && Array.isArray(findTags.value)) {
                findTags.value = findTags.value.filter((ft) => ft !== slug)
            }
        },

        updateSelectedFilters(payload) {
            setTimeout(() => {
                this.selectedFilters = payload
                this.sendFilters()
            }, 100)
        },

        updateSelectedOtherFilters(payload) {
            this.selectedOtherFilters = payload
            this.sendFilters()
        },

        sendFilters() {
            this.preparationRangeForSend()
            this.preparationFiltersForSend()
            this.preparationOtherFiltersForSend()
            this.saveFiltersInLocalStorage()
            this.saveQuery()
            this.refresh()
        },

        clearLocalFilters() {
            this.selectedFilters = []
            this.selectedOtherFilters = []
            this.filtersForSend = {}
            this.otherFiltersForSend = {}

            this.saveFiltersInLocalStorage()
            this.saveQuery()
        },

        resetFilter() {
            this.selectedFilters = []
            this.selectedOtherFilters = []
            this.filtersForSend = {}
            this.otherFiltersForSend = {}

            this.saveFiltersInLocalStorage()
            this.saveQuery()

            this.refresh()
        },
    },
})
