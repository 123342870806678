<template>
    <div class="relative z-120">
        <CustomMenu
            v-model="menu"
            hideTransitionSlider
        >
            <template #activator>
                <div
                    class="flex justify-between items-center cursor-pointer relative"
                    :class="mini && 'justify-center'"
                    @click="menuHandler"
                >
                    <div class="group flex p-2 items-center hover:bg-menu-purple70 dark:hover:bg-menu-blue770 rounded-1.25 transition-colors">
                        <img
                            v-if="currentWorkspace.image"
                            class="min-w-6 min-h-6 max-w-6 max-h-6 rounded-0.75"
                            :class="{ 'mr-3': !mini }"
                            :src="currentWorkspace.image"
                        >

                        <DefaultAvatarWorkspace
                            v-else
                            :class="{ 'mr-2': !mini }"
                            :name="currentWorkspace.name"
                        />

                        <div
                            v-if="!mini"
                            class="max-w-24 truncate text-gray-370 dark:text-white font-medium text-xs select-none"
                        >
                            {{ !!currentWorkspace ? currentWorkspace.name : '' }}
                        </div>

                        <ArrowVerticallyIcon
                            v-if="!mini"
                            class="w-4 h-4 text-gray-200 dark:text-gray-600 group-hover:text-gray-370 dark:group-hover:text-white transition-colors"
                        />
                    </div>

                    <div
                        v-if="!mini && !isMbView"
                        class="group min-w-6 min-h-6 max-h-6 relative left-0.75 flex items-center justify-center rounded-1.25 hover:bg-menu-purple70 dark:hover:bg-menu-blue770 cursor-pointer"
                        @click.stop="onChange"
                    >
                        <DoubleArrowIcon class="text-gray-200 dark:text-gray-600 dark:group-hover:text-gray-200 rotate-180" />
                    </div>
                </div>
            </template>

            <template #dropdown>
                <div class="w-51.25 overflow-hidden absolute -top-12.5 -left-0.5 bg-purple-60 dark:bg-blue-750 border border-gray-100 dark:border-gray-700 rounded-1.25 shadow-activeMenu dark:shadow-activeMenuDark">
                    <div class="py-2.5 px-2.5 flex items-center gap-x-2.5 border-b-1 border-gray-100 dark:border-gray-780">
                        <img
                            v-if="currentWorkspace.image"
                            class="min-w-6 min-h-6 max-w-6 max-h-6 rounded-1.25"
                            :src="currentWorkspace.image"
                        >

                        <DefaultAvatarWorkspace
                            v-else
                            :name="currentWorkspace.name"
                            mini
                        />

                        <div class="min-w-31.25 truncate text-purple-200 dark:text-white font-medium text-xs select-none">
                            {{ !!currentWorkspace ? currentWorkspace.name : '' }}
                        </div>

                        <div class="min-w-4 min-h-4 max-w-4 max-h-4">
                            <FavoriteIcon class="fill-yellow stroke-yellow" />
                        </div>
                    </div>

                    <div
                        v-if="workspaces.length"
                        class="max-h-46.5 py-1.25 grid gap-y-1 custom-scroll-y"
                    >
                        <template
                            v-for="(workspace, index) in workspaces"
                            :key="workspace.slug"
                        >
                            <div
                                class="flex items-center gap-x-3 py-1.25 px-2 mx-0.5 rounded-1.25 hover:bg-purple-70 dark:hover:bg-blue-770"
                                :class="loading ? 'cursor-wait' : 'cursor-pointer'"
                                @click.stop="changeWorkspace(workspace)"
                                @mouseenter="onMouseEnter(index)"
                                @mouseleave="onMouseLeave"
                            >
                                <img
                                    v-if="workspace.image"
                                    class="min-w-6 min-h-6 max-w-6 max-h-6 rounded-1.25"
                                    :src="workspace.image"
                                >

                                <DefaultAvatarWorkspace
                                    v-else
                                    :name="workspace.name"
                                    mini
                                />

                                <div class="max-w-32.5 truncate text-purple-200 dark:text-white font-medium text-xs">
                                    {{ workspace.name }}
                                </div>
                            </div>
                        </template>
                    </div>

                    <div
                        v-if="isOwner"
                        class="h-9.5 w-full flex items-center justify-center"
                        :class="{ 'border-t border-gray-100 dark:border-gray-780': workspaces.length }"
                    >
                        <div
                            class="w-full flex items-center justify-center gap-x-1 py-1.75 mx-0.5 rounded-1.25 transition-all text-gray-200 dark:text-gray-200 hover:text-gray-370 dark:hover:text-white hover:bg-purple-70 dark:hover:bg-blue-770 cursor-pointer"
                            @click="openCreateDialog"
                        >
                            <AddIcon class="w-5 h-5" />

                            <div class="text-xs">
                                Create workspace
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CustomMenu>

        <CreateWorkspaceDialog v-model="showCreateDialog" />
    </div>
</template>

<script setup>
import {
    ref, computed, watch, inject,
} from 'vue'
import { useRouter } from 'vue-router'

import CustomMenu from '@/components/menu/CustomMenu/CustomMenu.vue'
import ArrowVerticallyIcon from '@/components/icons/arrow/ArrowVerticallyIcon.vue'
import CreateWorkspaceDialog from '@/components/dialogs/CreateWorkspaceDialog.vue'
import DefaultAvatarWorkspace from '@/components/defaultAvatars/DefaultAvatarWorkspace.vue'
import DoubleArrowIcon from '@/components/icons/arrow/DoubleArrowIcon.vue'
import AddIcon from '@/components/icons/AddIcon.vue'
import FavoriteIcon from '@/components/icons/FavoriteIcon.vue'

import { useAuthorizationStore } from '@/store/authorizationStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'

import { ROUTES } from '@/constants/routes'
import { viewPorts } from '@/constants/viewPorts'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    isOwner: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['onChange'])

const authorizationStore = useAuthorizationStore()
const sortFilterTableStore = useSortFilterTableStore()

const router = useRouter()

const currentViewSize = inject('currentViewSize')

const loading = ref(false)

const menu = ref(false)
const showCreateDialog = ref(false)
const activeIndex = ref(-1)

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)
const currentWorkspace = computed(() => authorizationStore.workspace || {})
const workspaces = computed(() => authorizationStore.workspaces.filter((w) => w.slug !== currentWorkspace.value.slug))
const workspaceSlug = computed(() => (currentWorkspace.value ? currentWorkspace.value.slug : null))

function openCreateDialog() {
    showCreateDialog.value = true
    closeMenu()
}

function onMouseEnter(index) {
    activeIndex.value = index
}

function onMouseLeave() {
    activeIndex.value = -1
}

function menuHandler() {
    if (authorizationStore.isAccountReview) {
        return
    }

    menu.value = !menu.value
}

function closeMenu() {
    menu.value = false
}

function onChange() {
    emits('onChange', !props.mini)
}

async function changeWorkspace(workspace) {
    if (workspaceSlug.value === workspace.slug) {
        return
    }

    if (loading.value) {
        return
    }

    loading.value = true

    await authorizationStore.getCheckWorkspace(workspace.slug)

    closeMenu()

    loading.value = false
}

watch(workspaceSlug, (current, old) => {
    if (!!current && !!old) {
        sortFilterTableStore.removeFilters()
        sortFilterTableStore.clearTableSettings()

        const { owner_blocked } = currentWorkspace.value

        if (owner_blocked) {
            router.push({ name: ROUTES.accountDisabled })
        } else {
            router.push({ name: ROUTES.reporting, params: { workspace: current } })
        }
    } else {
        router.push({ name: ROUTES.authLogin })
    }
})
</script>
