import { defineStore } from 'pinia'

import {
    attachTrafficSourceToPool,
    detachTrafficSourceFromPool,
    getAvailableTrafficSources,
    updateTrafficSourcePool,
} from '@/api/pool-routes'
import { getFilterOnPageInLocalStorageByWorkspaceId, setFilterOnPageInLocalStorageByWorkspaceId } from '@/helpers/save-sorting-local-storage-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useDetailNumberPoolStore } from '@/store/call-tracking/numberPools/detailNumberPoolStore'
import { usePermissionsStore } from '@/store/permissionsStore'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { FILTER_NAME } from '@/constants/localFilters'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE, DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE_WITH_ACTIONS } from '@/constants/headersTable/detailNumberPoolTrafficSourcesHeadersTable'
import { INTEGRATIONS } from '@/constants/call-tracking/integrations/integrations'

export const useDetailNumberPoolTrafficSourcesStore = defineStore('detailNumberPoolTrafficSourcesStore', {
    state: () => ({
        loading: false,
        actionLoading: false,

        onPage: 25,
        onPageAttachTrafficSources: 25,

        id: null,
        availableTrafficSources: [],
        trafficSources: [],

        availableTrafficSourcesFilters: [FILTER_NAME],
        trafficSourcesFilters: [
            FILTER_NAME,
        ],

        search: null,
    }),

    getters: {
        headersByPermissions() {
            const permissionsStore = usePermissionsStore()

            return (permissionsStore.permissions.numberPools.edit || permissionsStore.permissions.trafficSources.view)
                ? DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE_WITH_ACTIONS
                : DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE
        },
    },

    actions: {
        changeOnPage(onPage) {
            this.onPage = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, `${SETTINGS_HEADER_TABLE.numberPoolTrafficSources}.${this.id}`)
        },

        getLocalStorageOnPage() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(`${SETTINGS_HEADER_TABLE.numberPoolTrafficSources}.${this.id}`)

            this.onPage = savedOnPage ? Number(savedOnPage) : 25
        },

        changeOnPageAttachTrafficSource(onPage) {
            this.onPageAttachTrafficSources = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, `${SETTINGS_HEADER_TABLE.numberPoolAttachTrafficSources}.${this.id}`)
        },

        getLocalStorageOnPageAttachTrafficSources() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(`${SETTINGS_HEADER_TABLE.numberPoolAttachTrafficSources}.${this.id}`)

            this.onPageAttachTrafficSources = savedOnPage ? Number(savedOnPage) : 25
        },

        setData(trafficSources, id) {
            this.id = id
            this.trafficSources = trafficSources
        },

        onSearch(search) {
            this.search = search
        },

        getQueryLocalStorage() {
            const localFilterTableStore = useLocalFilterTableStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.detailNumberPoolAvailableTrafficSources, this.availableTrafficSourcesFilters)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.detailNumberPoolAvailableTrafficSources, this.id)
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources, this.trafficSourcesFilters)
            localFilterTableStore.setHeaders(SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources, this.headersByPermissions)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.detailNumberPoolTrafficSources, this.id)
            this.getLocalStorageOnPage()
            this.getLocalStorageOnPageAttachTrafficSources()
        },

        async goToLoadAvailableTrafficSources(withoutLoading = false) {
            const defaultStore = useDefaultStore()
            const detailNumberPoolsStore = useDetailNumberPoolStore()

            this.loading = !withoutLoading

            const { success, payload, message } = await getAvailableTrafficSources(detailNumberPoolsStore.slug)

            if (success) {
                this.availableTrafficSources = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async goToAttachTrafficSourceToPool(formData) {
            const defaultStore = useDefaultStore()
            const detailNumberPoolsStore = useDetailNumberPoolStore()
            this.actionLoading = true

            const { success, payload, message } = await attachTrafficSourceToPool(detailNumberPoolsStore.slug, formData)

            if (success) {
                this.trafficSources = payload.traffic_sources
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToDetachTrafficSource({ slug, traffic_source_slugs }) {
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, message } = await detachTrafficSourceFromPool(slug, traffic_source_slugs)

            if (success) {
                this._removeTrafficSourcesAfterDetach(traffic_source_slugs)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success }
        },

        async goToUpdateTrafficSource({ slug, formData, type }) {
            const defaultStore = useDefaultStore()
            this.actionLoading = true

            const { success, payload, message } = await updateTrafficSourcePool(slug, formData)

            if (success) {
                this._updateConversionAfterUpdateTrafficSource(formData.traffic_source_slug, payload, type)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false
        },

        _updateConversionAfterUpdateTrafficSource(slug, payload, type) {
            switch (type) {
            case INTEGRATIONS.google.id:
                this._updateGoogleConversionAfterUpdateTrafficSource(slug, payload.google_ads_conversion_id)
                return
            case INTEGRATIONS.facebook.id:
                this._updateFacebookEventAfterUpdateTrafficSource(slug, payload.facebook_ads_event_id)
            }
        },

        _updateGoogleConversionAfterUpdateTrafficSource(slug, conversionId) {
            const findIndex = this.trafficSources.findIndex((trafficSource) => trafficSource.slug === slug)

            if (findIndex + 1) {
                this.trafficSources[findIndex].pivot = { ...this.trafficSources[findIndex].pivot, google_ads_conversion_id: conversionId }
            }
        },

        _updateFacebookEventAfterUpdateTrafficSource(slug, facebookAdsEventId) {
            const findIndex = this.trafficSources.findIndex((trafficSource) => trafficSource.slug === slug)

            if (findIndex + 1) {
                this.trafficSources[findIndex].pivot = { ...this.trafficSources[findIndex].pivot, facebook_ads_event_id: facebookAdsEventId }
            }
        },

        _removeTrafficSourcesAfterDetach(slugs) {
            this.trafficSources = this.trafficSources.filter((trafficSource) => !slugs.includes(trafficSource.slug))
        },
    },
})
