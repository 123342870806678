const callDate = {
    name: 'Call Date',
    value: 'created_at',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byDate',
    },
    disabled: true,
}
const campaign = {
    name: 'Campaign',
    value: 'campaign_name',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byCampaign',
    },
}
const vendor = {
    name: 'Vendor',
    value: 'vendor_name',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byVendor',
    },
}
const callerId = {
    name: 'Caller ID',
    value: 'caller_number',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byCaller',
    },
}
const dialed = {
    name: 'Dialed',
    value: 'dialed',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byDialed',
    },
}
const dupe = {
    name: 'Dupe',
    value: 'dupe',
    align: 'center',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byDupe',
    },
}
const destination = {
    name: 'Destination',
    value: 'destination_name',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byDestination',
    },
}
const buyer = {
    name: 'Buyer',
    value: 'buyer_name',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byBuyer',
    },
}
const revenue = {
    name: 'Revenue',
    value: 'revenue',
    align: 'center',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byRevenue',
    },
}
const payout = {
    name: 'Payout',
    value: 'payout',
    align: 'center',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byPayout',
    },
}
const ttc = {
    name: 'TTC',
    value: 'ttc',
    align: 'center',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byTtc',
    },
}
const duration = {
    name: 'Duration',
    value: 'duration',
    align: 'center',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byDuration',
    },
}
const status = {
    name: 'Status',
    value: 'status',
    align: 'center',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byStatus',
    },
    width: 100,
}
const failReason = {
    name: 'Fail Reason',
    value: 'failReason',
    align: 'center',
    width: 100,
}
const record = {
    name: 'Rec.',
    value: 'record',
    align: 'center',
}
const hangup = {
    name: 'Hang Up',
    value: 'hangup_source',
    align: 'center',
    sortSettings: {
        onlySort: true,
        key: '',
        sortBy: 'byHangup',
    },
}
const tag = {
    name: 'Tag',
    value: 'tags',
}
const actions = {
    name: 'Actions',
    value: 'actions',
    align: 'center',
    disabled: true,
}

const MANAGER_HEADERS = [
    callDate,
    campaign,
    vendor,
    callerId,
    dialed,
    dupe,
    destination,
    buyer,
    revenue,
    payout,
    ttc,
    duration,
    hangup,
    tag,
    status,
    failReason,
    record,
    actions,
]

const VENDOR_HEADERS = [
    callDate,
    campaign,
    vendor,
    callerId,
    dialed,
    dupe,
    revenue,
    duration,
    hangup,
    tag,
    status,
    record,
    actions,
]

const BUYER_HEADERS = [
    callDate,
    callerId,
    destination,
    payout,
    duration,
    hangup,
    tag,
    status,
    record,
    actions,
]

export { MANAGER_HEADERS, VENDOR_HEADERS, BUYER_HEADERS }
