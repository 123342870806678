import axios from '@/plugins/axios'
import axiosExportApi from '@/plugins/axiosExport'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getChartData = (params) => axios.get(`${getWorkspaceId()}/calls/chart`, { params })

const getAggregatedGroups = (params) => axios.get(`${getWorkspaceId()}/calls/agg-groups`, { params })
const getAggregatedData = (params) => axios.get(`${getWorkspaceId()}/calls/aggregated`, { params })

const getLog = (params) => axios.get(`${getWorkspaceId()}/calls/log`, { params })

const exportLogCSVData = ({ params, onDownloadProgress }) => axiosExportApi.get(`export/${getWorkspaceId()}/calls/log/csv`, { params, responseType: 'blob', onDownloadProgress })

const generateLogXLSXData = (formData) => axiosExportApi.post(`export/${getWorkspaceId()}/calls/log/xlsx`, formData)
const getLogXLSXStatuses = () => axiosExportApi.get(`export/${getWorkspaceId()}/calls/log/xlsx`)
const downloadLogXLSXData = (slug) => axiosExportApi.get(`export/${getWorkspaceId()}/calls/log/xlsx/${slug}`, { responseType: 'blob' })

const exportAggregatedData = ({ params, onDownloadProgress }) => axiosExportApi.get(`export/${getWorkspaceId()}/calls/aggregated`, { params, responseType: 'blob', onDownloadProgress })

const adjustPrices = ({ formData, uuid }) => axios.patch(`${getWorkspaceId()}/calls/${uuid}/price`, formData)
const stopLiveCall = (uuid) => axios.delete(`${getWorkspaceId()}/calls/${uuid}/live`)

const getTrafficSourcesParams = () => axios.get(`${getWorkspaceId()}/traffic-sources/params`)

const getCallEvents = ({ uuid, params }) => axios.get(`${getWorkspaceId()}/calls/${uuid}/events`, { params })

const getBuyersAndVendors = () => axios.get(`${getWorkspaceId()}/buyers-and-vendors`)

const getCallsFilters = (params) => axios.get(`${getWorkspaceId()}/calls/filters`, { params })
const getCallsFilterValues = ({ params, filter }) => axios.get(`${getWorkspaceId()}/calls/filters/${filter}`, { params })
const getCallersKeysFilter = (params) => axios.get('callers/keys', { params })
const getSessionDataKeysFilter = () => axios.get('session-data/keys')

const attachTags = ({ formData, uuid }) => axios.patch(`${getWorkspaceId()}/calls/${uuid}/tags`, formData)
const detachTags = ({ data, uuid }) => axios.delete(`${getWorkspaceId()}/calls/${uuid}/tags`, { data })

export {
    getChartData,
    getAggregatedGroups,
    getAggregatedData,
    getLog,
    getTrafficSourcesParams,
    adjustPrices,
    stopLiveCall,
    getCallsFilters,
    getCallsFilterValues,
    getCallEvents,
    exportLogCSVData,
    generateLogXLSXData,
    getLogXLSXStatuses,
    downloadLogXLSXData,
    exportAggregatedData,
    getCallersKeysFilter,
    getSessionDataKeysFilter,
    attachTags,
    detachTags,
    getBuyersAndVendors,
}
