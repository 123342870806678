import { defineStore } from 'pinia'

import { usePermissionsStore } from '@/store/permissionsStore'
import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useLocalFilterTableStore } from '@/store/localFilterTableStore'

import { FILTER_STATUS, FILTER_TYPE_NUMBER } from '@/constants/localFilters'
import { CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE, CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE_WITH_ACTIONS } from '@/constants/headersTable/campaignTrackingNumbersHeadersTable'
import { TYPE_NUMBER } from '@/constants/call-tracking/campaign/typeNumber'
import { NUMBERABLE_TYPES } from '@/constants/numberableTypes'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'

export const useDetailCampaignTrackingNumberTableStore = defineStore('detailCampaignTrackingNumberTable', {
    state: () => ({
        numbers: [],
        numberPools: [],

        vendors: [],

        tableFilters: [
            FILTER_STATUS,
            FILTER_TYPE_NUMBER,
        ],
    }),
    getters: {
        attachedVendors(state) {
            return state.vendors
        },

        vendorsWithoutDuplicate({ numbers, numberPools }) {
            const items = [...numbers, ...numberPools]
                .filter((item) => item.vendor)
                .map((item) => item.vendor)

            return items.filter((vendor, index, vendors) => index === vendors.findIndex((v) => v.slug === vendor.slug))
        },

        localItems(state) {
            return [...state.numbers, ...state.numberPools]
        },

        headersByPermissions() {
            const permissionsStore = usePermissionsStore()

            if (permissionsStore.permissions.campaigns.edit || permissionsStore.permissions.numbers.view || permissionsStore.permissions.numberPools.view) {
                return CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE_WITH_ACTIONS
            }

            return CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE
        },

        numbersSlugsForCounters(state) {
            return state.numbers.map(({ slug }) => slug)
        },
    },
    actions: {
        updateTrackingNumberStatus(payload) {
            payload.forEach((number) => {
                const index = this.numbers.findIndex((n) => n.slug === number.slug)
                if (index + 1) {
                    this.numbers[index].status_id = number.status_id
                }
            })
        },

        updateNumberPoolStatus(payload) {
            payload.forEach((numberPool) => {
                const index = this.numberPools.findIndex((n) => n.slug === numberPool.slug)

                if (index + 1) {
                    this.numberPools[index].status_id = numberPool.status_id
                }
            })
        },

        getQueryLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()
            const localFilterTableStore = useLocalFilterTableStore()
            localFilterTableStore.initializeTableSettings(SETTINGS_HEADER_TABLE.campaignTrackingNumber, this.tableFilters)
            localFilterTableStore.setHeaders(SETTINGS_HEADER_TABLE.campaignTrackingNumber, this.headersByPermissions)
            localFilterTableStore.getQueryLocalStorage(SETTINGS_HEADER_TABLE.campaignTrackingNumber, detailCampaignStore.id)
        },

        setVendors(vendors) {
            this.vendors = vendors
        },

        checkVendorForNumbers(number, vendor) {
            return number.vendor && number.vendor.slug === vendor.slug && vendor.pivot.numberable_id === number.id && vendor.pivot.numberable_type === NUMBERABLE_TYPES.STATIC_NUMBER
        },

        checkVendorForNumberPool(numberPool, vendor) {
            return numberPool.vendor && numberPool.vendor.slug === vendor.slug && vendor.pivot.numberable_id === numberPool.id && vendor.pivot.numberable_type === NUMBERABLE_TYPES.DYNAMIC_NUMBER
        },

        parseNumbers({ numbers }) {
            return numbers.map((number) => ({
                id: number.id,
                slug: number.slug,
                number: number.number,
                name: number.name,
                facebook_ads_event_id: number.facebook_ads_event_id || null,
                vendor_id: number.vendor_id,
                vendor: this.vendors.find((vendor) => this.checkVendorForNumbers(number, vendor)),
                type: TYPE_NUMBER.STATIC,
                status_id: number.status_id,
                lifetime_exceeded: number.lifetime_exceeded,
                cap_enabled: number.cap_enabled,
                max_concurrency_enabled: number.max_concurrency_enabled,
                max_concurrency: number.max_concurrency,
                cap_on_id: number.cap_on_id,
                live: number.live,
                hourly: number.hourly,
                hourly_cap: number.hourly_cap,
                daily: number.daily,
                daily_cap: number.daily_cap,
                monthly: number.monthly,
                monthly_cap: number.monthly_cap,
                global_cap: number.global_cap,
                global_revenue_cap: number.global_revenue_cap,
                traffic_source_id: number.traffic_source_id,
                traffic_source: number.traffic_source,
                conversion: number.conversion,
                conversion_id: number.conversion_id,
            }))
        },

        parseNumberPools({ phone_number_pools = [] }) {
            return phone_number_pools.map((numberPool) => ({
                id: numberPool.id,
                slug: numberPool.slug,
                number: 'Number Pool',
                name: numberPool.name,
                vendor_id: numberPool.vendor_id,
                vendor: this.vendors.find((vendor) => this.checkVendorForNumberPool(numberPool, vendor)),
                type: TYPE_NUMBER.DYNAMIC,
                status_id: numberPool.status_id,
                cap_enabled: null,
                cap_on_id: null,
                max_concurrency_enabled: null,
                max_concurrency: null,
                live: null,
                hourly: null,
                hourly_cap: null,
                daily: null,
                daily_cap: null,
                monthly: null,
                monthly_cap: null,
                global_cap: null,
                global_revenue_cap: null,
                conversion: null,
                conversion_id: null,
                facebook_ads_event_id: null,
            }))
        },

        setNumbers(numbers) {
            this.numbers = numbers
        },

        setNumberPools(numberPools) {
            this.numberPools = numberPools
        },

        addNumbers(numbers) {
            this.numbers = [...this.numbers, ...numbers]
        },

        addNumberPools(numberPools) {
            this.numberPools = [...this.numberPools, ...numberPools]
        },

        _removeNumberPoolsAfterDelete(numberPoolSlugs) {
            this.numberPools = this.numberPools.filter((numberPool) => !numberPoolSlugs.includes(numberPool.slug))
        },

        _removeTrackingNumbersAfterDelete(numbers) {
            this.numbers = this.numbers.filter((number) => !numbers.includes(number.slug))
        },
    },
})
