import { defineStore } from 'pinia'

import { getNotifications, updateNotification } from '@/api/notifications-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

export const useNotificationsStore = defineStore('notificationsStore', {
    state: () => ({
        loading: false,
        loadingRead: false,
        notifications: [],
        notificationInterval: null,
        loadingReadIds: {},
        smallNotificationMenu: true,
    }),

    getters: {
        showNotification() {
            const authorizationStore = useAuthorizationStore()
            return authorizationStore.isOwner && !authorizationStore.isAccountReview
        },

        unreadNotifications(state) {
            return state.notifications.filter((notification) => !notification.read_at)
        },

        lastUnreadUrgentlyNotification() {
            return this.unreadNotifications.find((notification) => notification.type === 'recommendation')
        },

        readNotifications(state) {
            return state.notifications.filter((notification) => notification.read_at)
        },
    },

    actions: {
        changeSmallNotificationMenu(value) {
            this.smallNotificationMenu = value
        },

        async loadNotifications() {
            const defaultStore = useDefaultStore()
            this.loading = true

            const { success, payload, message } = await getNotifications()

            if (success) {
                this.notifications = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        addNotification(notification) {
            this.smallNotificationMenu = true
            this.notifications.push(notification)
        },

        async onRead(slug) {
            const defaultStore = useDefaultStore()

            if (slug && this.loadingReadIds[slug]) {
                return
            }

            if (!slug && this.loadingRead) {
                return
            }

            this.setLoadingReadId({ slug })

            const { success, message } = await updateNotification(slug ? { slug } : null)

            if (success) {
                this.notificationsAfterRead(slug)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.setLoadingReadId({ slug, loading: false })
        },

        setLoadingReadId({ id, loading = true }) {
            if (id) {
                this.loadingReadIds[id] = loading
            } else {
                this.loadingRead = loading
            }
        },

        notificationsAfterRead(slug) {
            if (slug) {
                const index = this.notifications.findIndex((item) => item.slug === slug)

                if (index + 1) {
                    this.notifications.splice(index, 1, { ...this.notifications[index], read_at: new Date() })
                }
            } else {
                this.markAsReadAllNotifications()
            }
        },
        markAsReadAllNotifications() {
            this.notifications.forEach((notification) => {
                notification.read_at = new Date()
            })
        },
    },
})
